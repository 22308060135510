import { Component, Input, Output, EventEmitter } from '@angular/core';
// import { area } from 'd3';
import { environment } from 'src/environments/environment';
import { CommunicationService } from '@app/@shared/services/map-elements-services/communication.service';
import { SearchPanelModel } from '@app/model/search-panel.model';
@Component({
  selector: 'google-map-view',
  templateUrl: './google-map-view.component.html',
  styleUrls: ['./google-map-view.component.scss']
})
export class GoogleMapView {
  apiKey = environment.googleApiKey;
  @Input() address!: string;
  @Input() searchModel!: SearchPanelModel
  @Output() areaExtentCalculated = new EventEmitter<{ x: number, y: number }[]>();

  private map!: google.maps.Map;
  private marker!: google.maps.Marker;
  private geocoder = new google.maps.Geocoder();
  coordinates: { x: number, y: number }[] = [];
 

  constructor(
    private communicationService: CommunicationService,
  ) { }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes['address'] && this.address) {
  //     this.geocodeAddress(this.address);
  //   }
  // }

  ngAfterViewInit(): void {
    this.loadGoogleMapsScript().then(() => {
      this.initMap();
    }).catch(err => {
      console.error('Error loading Google Maps script', err);
    });
  }

  private loadGoogleMapsScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (document.getElementById('google-maps-script')) {
        resolve();
        return;
      }
      const script = document.createElement('script');
      script.id = 'google-maps-script';
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => resolve();
      script.onerror = (error: any) => reject(error);
      document.head.appendChild(script);
    });
  }

  private initMap(): void {
    const mapElement = document.getElementById('map') as HTMLElement;
    this.map = new google.maps.Map(mapElement, {
      center: { lat: -38.4161, lng: -63.6167 }, // Coordenadas para el centro de Argentina
      zoom: 5
    });

    this.marker = new google.maps.Marker({
      map: this.map
    });
  }

  private geocodeAddress(address: string): void {
    try {
      if (this.isCoordinatesSearch(address)) {
        const coordinates = this.extractCoordinates(address);
        this.searchByCoordinates(coordinates);
      } else {
        this.searchByAddress(address);
      }
    } catch (error) {
      console.error('An error occurred while geocoding the address: ', error);
    }
  }

  private isCoordinatesSearch(address: string): boolean {
    return address.startsWith('searchByCoordinates:');
  }

  private extractCoordinates(address: string): { lat: number; lng: number } | null {
    const coordinatesStr = address.replace('searchByCoordinates:', '').trim();
    const coordinates = coordinatesStr.split(',');
  
    if (coordinates.length === 2) {
      const lat = parseFloat(coordinates[0].trim());
      const lng = parseFloat(coordinates[1].trim());
  
      if (!isNaN(lat) && !isNaN(lng)) {
        return { lat, lng };
      } else {
        console.error('Invalid coordinates format.');
        return null;
      }
    }
  
    console.error('Invalid coordinates format. Expected "lat, lon".');
    return null;
  }

  private searchByCoordinates(coordinates: { lat: number; lng: number } | null): void {
    if (coordinates) {
      const location = new google.maps.LatLng(coordinates.lat, coordinates.lng);
      this.updateMapLocation(location);
      this.coordinates.push({ x: coordinates.lat, y: coordinates.lng });
    }
  }
  

  private searchByAddress(address: string): void {
    this.geocoder.geocode({ address: address }, (results, status) => {
      if (status === 'OK' && results[0]) {
        let location = results[0].geometry.location;
        this.updateMapLocation(location);
        this.coordinates.push({
          x: location.lat(),
          y: location.lng()
        });
      } else {
        console.error('Geocode was not successful for the following reason: ' + status);
      }
    });
  }

  private updateMapLocation(location: google.maps.LatLng): void {
    this.map.setCenter(location);
    this.map.setZoom(21);
    this.marker.setPosition(location);
    this.marker.setVisible(true);

    this.coordinates = this.calculateSquareCorners(location);
    this.communicationService.emitLocation(location);
    this.communicationService.emitAreaExtent(this.coordinates, this.searchModel);

  }

  private calculateSquareCorners(center: google.maps.LatLng): { x: number, y: number }[] {
    const kmPerDegreeLat = 0.2 / 111;
    const kmPerDegreeLng = 0.2 / (111.32 * Math.cos(center.lat() * Math.PI / 180));

    // Calcular la distancia en kilómetros para el lado del cuadrado (hipotenusa)
    const splitDistance = this.searchModel.data["selectedDistance"].split(' ');
    const distance = +splitDistance[0] * ((splitDistance[1] == "Km") ? 1 : 0.001);
    const diagonalKm = distance * 4 * 2;

    // Calcular las coordenadas de las esquinas
    const northeast = {
      y: center.lat() + (diagonalKm * kmPerDegreeLat),
      x: center.lng() + (diagonalKm * kmPerDegreeLng)
    };
    const southeast = {
      y: center.lat() - (diagonalKm * kmPerDegreeLat),
      x: center.lng() + (diagonalKm * kmPerDegreeLng)
    };
    const southwest = {
      y: center.lat() - (diagonalKm * kmPerDegreeLat),
      x: center.lng() - (diagonalKm * kmPerDegreeLng)
    };
    const northwest = {
      y: center.lat() + (diagonalKm * kmPerDegreeLat),
      x: center.lng() - (diagonalKm * kmPerDegreeLng)
    };

    const validateCoordinates = (coord: { x: number, y: number }) => {
      return {
        x: isFinite(coord.x) ? coord.x : 0,
        y: isFinite(coord.y) ? coord.y : 0
      };
    };

    const validatedNortheast = validateCoordinates(northeast);
    const validatedSoutheast = validateCoordinates(southeast);
    const validatedSouthwest = validateCoordinates(southwest);
    const validatedNorthwest = validateCoordinates(northwest);

    const areaExtent = [
      { x: validatedSouthwest.x, y: validatedSouthwest.y },
      { x: validatedSoutheast.x, y: validatedSoutheast.y },
      { x: validatedNortheast.x, y: validatedNortheast.y },
      { x: validatedNorthwest.x, y: validatedNorthwest.y },
      { x: validatedSouthwest.x, y: validatedSouthwest.y }, // Repetir el primer punto para cerrar el cuadrado
    ];

    return areaExtent;
  }

  getGeocodeAddress(address: string): void {
    this.geocodeAddress(address);
  }
}