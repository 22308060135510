import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Connection } from '@app/@shared/model/connection.model';
import { Container } from '@app/@shared/model/container.model';
import { Device } from '@app/@shared/model/device.model';
import { DeviceService } from '@app/@shared/services/map-elements-services/device.service';
import { TranslateService } from '@ngx-translate/core';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook, Worksheet } from 'exceljs';
import * as saveAs from 'file-saver';
import { ExcelJSHelperService } from '@app/@shared/services/aux-services/excelJS-helper.service';
import { DeviceDetailComponent } from '../device-detail/device-detail.component';
import { ContainerService } from '@app/@shared/services/map-elements-services/container.service';
import { TraceService } from '@app/@shared/services/map-elements-services/trace.service';
import { firstValueFrom } from 'rxjs';
import { ConnectionService } from '@app/@shared/services/map-elements-services/connection.service';
import { DataTransferService } from '@app/@shared/services/d3/data-transfer.service';
import { AppRoutingService } from '@app/app-routing.service';
import { HTMLtoSVGDownlaodService } from '@app/@shared/services/d3/html-to-svg-download.service';
import { Cons } from '@app/@shared/cons/cons';
import { GeneralUtilsService } from '@app/@shared/services/aux-services/general-utils.service';
import { ExcelReportService } from '@app/@shared/services/excel-report.service';

@Component({
  selector: 'container-detail',
  templateUrl: './container-detail.component.html',
  styleUrls: ['./container-detail.component.scss'],
})
export class ContainerDetailComponent implements OnInit {

  @Output() connectionSelected = new EventEmitter<Connection>();
  @ViewChild(DeviceDetailComponent, { static: false }) deviceDetail: DeviceDetailComponent;

  ds_accordion_element = [];
  accordion_element_selected = [];
  ds_accordion_device = [];
  ds_accordion_device_selected = [];

  showLoading: boolean = false;

  _entity: Container;
  projectDataDetail: any;
  projectDataDetailUserfId2: any;
  containerID: number;
  mslink: number;
  platformID: number;
  traceData: any[];
  ductData: any;
  isLoading: boolean;
  excelData: any;
  devicesWithConnections: any[] = [];
  deviceID: number[];
  workbook: Workbook;
  name: string;
  isCommentPopupVisible: boolean = false;
  entityName = Cons._CONTAINER;


  @Input('entity')
  get entity() {
    return this._entity;
  }
  set entity(value) {
    if (value == this._entity) {
      return;
    }
    this._entity = value;
    this.name = this._entity.name;
    this.platformID = this._entity.platformID;
    this.containerID = this._entity.containerID;
    this.mslink = this._entity.containerAttributes.mslink;
    this.projectDataDetail = this._entity.containerAttributes;
    const containerAttributes = this._entity.containerAttributes;


    const userFldFields = Object.keys(containerAttributes)
      .filter(key => key.startsWith('userfld') || key.startsWith('userFld'))
      .reduce((obj, key) => {
        obj[key] = containerAttributes[key];
        return obj;
      }, {});

    this.projectDataDetailUserfId2 = userFldFields;
    if (containerAttributes.fecha_cao) {
      this._entity.containerAttributes.fecha_cao = this.generalUtilsService.convertToDDMMYYYY(containerAttributes?.fecha_cao);
    }
    this.getTraceAsociatedWithContainerID();
    this.loadEntity();
  }

  detail: Device;
  traceDetail: any;

  constructor(
    private trans: TranslateService,
    private deviceService: DeviceService,
    private excelJSHelperService: ExcelJSHelperService,
    private containerService: ContainerService,
    private traceService: TraceService,
    private connectionService: ConnectionService,
    private dataTransferService: DataTransferService,
    private appRoutingService: AppRoutingService,
    private htmlToSVGDownlaodService: HTMLtoSVGDownlaodService,
    public generalUtilsService: GeneralUtilsService,
    private excelReportService: ExcelReportService
  ) { }

  ngOnInit() { }

  loadEntity() {
    //Close search panel
    this.ds_accordion_element = [];

    this.ds_accordion_element.push({
      title:
        String.prototype.upperFirstLetter(this.entity.entityName) +
        ' ' +
        this.trans.instant('general.review'),
      data: this.entity,
    });

    this.accordion_element_selected = this.ds_accordion_element;

    this.ds_accordion_device = [];
    this.ds_accordion_device.push({
      title: this.trans.instant('comp.search-panel.devices'),
      data: this.entity.device,
    });

    this.ds_accordion_device_selected = this.ds_accordion_device;
  }

  selectItem($event, data = null) {

    if ($event?.data?.traceID) {

      this.showLoading = true;
      this.traceService.getByID($event.data.traceID).subscribe((res) => {
        if (res) {
          this.accordion_element_selected = [];
          this.traceDetail = res;
        }
        this.showLoading = false;
      });
    } else {
      if (!$event || ($event.length === 0 && !data)) {
        const defaultItem = data && data.length > 0 ? data[0] : null;
        if (defaultItem) {
          this.deviceService.getByID(defaultItem.deviceID).subscribe((res) => {
            if (res) {
              this.accordion_element_selected = [];
              this.detail = res;
            }
          });
        }
        $event = data;
      }

      if (Array.isArray($event)) {
        this.downloadDevicesGraphsOneByOne($event);

      } else {
        this.deviceService.getByID($event.data.deviceID).subscribe((res) => {
          if (res) {
            this.accordion_element_selected = [];
            this.detail = res;
          }
        });
      }
    }
  }

  async downloadDevicesGraphsOneByOne(devices: any[]) {
    if (!devices || devices.length === 0) {
      console.warn('No devices to process.');
      return;
    }

    this.showLoading = true;

    for (const device of devices) {
      try {
        const res = await firstValueFrom(this.deviceService.getByID(device.deviceID));
        if (res) {
          this.detail = res;
          await this.htmlToSVGDownlaodService.downloadAllSVGs(res);
        }
      } catch (error) {
        console.error(`Error processing device ${device.deviceID}:`, error);
      }
    }

    this.showLoading = false;
  }


  exportDuctWithAsociatedTraces = async ($event, data) => {
    this.isLoading = true;
    $event.cancel = true;
    let traceDataArrayFromRes: any[] = [];
    try {

      const principalSheetName = this.trans.instant('general.duct')?.toUpperCase();

      //Main headers
      const ductHeaders = [
        "MSLINK",
        this.trans.instant('comp.search-panel.excel-header.name')?.toUpperCase(),
        this.trans.instant('comp.search-panel.excel-header.description')?.toUpperCase(),
      ];

      //External headers
      const traceHeaders = [
        this.trans.instant('comp.search-panel.excel-header.buffer')?.toUpperCase(),
        this.trans.instant('comp.search-panel.excel-header.color')?.toUpperCase(),
        this.trans.instant('comp.search-panel.excel-header.service')?.toUpperCase(),
        this.trans.instant('comp.search-panel.excel-header.fiber-order')?.toUpperCase()
      ];

      //main data
      const filteredTraceData = this.traceData.map(trace => ({
        msLink: trace.msLink,
        name: trace.name,
        description: trace.description
      }));

      if (Array.isArray(data)) {
        for (const trace of data) {
          const res = await firstValueFrom(this.traceService.getByID(trace.traceID));
          traceDataArrayFromRes = traceDataArrayFromRes.concat(res);
        }
      } else {
        const res = await firstValueFrom(this.traceService.getByID(data.traceID));
        traceDataArrayFromRes = traceDataArrayFromRes.concat(res);
      }

      //external data
      const filteredTraceDataWithFiberInfo = traceDataArrayFromRes.map(trace => {
        const fiberArray = trace.fiber || [];
        return {
          name: trace.name,
          data: fiberArray.map(fiberItem => ({
            buffer: fiberItem.fiberAttributes?.buffer || "",
            color: fiberItem.fiberAttributes?.color || "",
            fiberOrder: fiberItem.fiberOrder || "",
            service: fiberItem.serviceTrace?.[0]?.service?.name || ""
          }))
        };
      });


      this.excelReportService.generateExcel(filteredTraceData, ductHeaders, principalSheetName, 'DuctWithAssociatedTraces', this.entity.name, traceHeaders, filteredTraceDataWithFiberInfo);
    } catch (error) {
      console.error('Error exporting data:', error);
    } finally {
      this.isLoading = false;
    }
  };


  exportContainerWithAsociatedDevices = async ($event) => {
    this.isLoading = true;
    $event.cancel = true;
    let traceDataArrayFromRes: any[] = [];
    const devices: Device[] = this.ds_accordion_device[0].data;

    try {

      const principalSheetName = this.trans.instant('Container')?.toUpperCase();

      //Main headers
      const containerHeaders = [
        this.trans.instant('MSLINK')?.toUpperCase(),
        this.trans.instant('comp.search-panel.excel-header.name')?.toUpperCase(),
        this.trans.instant('comp.search-panel.excel-header.description')?.toUpperCase(),
      ];

      //External headers
      const traceLabel = this.trans.instant('comp.search-panel.excel-header.trace')?.toUpperCase();
      const bufferLabel = this.trans.instant('comp.search-panel.excel-header.buffer')?.toUpperCase();
      const colorLabel = this.trans.instant('comp.search-panel.excel-header.color')?.toUpperCase();
      const serviceLabel = this.trans.instant('comp.search-panel.excel-header.service')?.toUpperCase();
      const term1Label = this.trans.instant('comp.search-panel.excel-header.term1')?.toUpperCase();
      const term2Label = this.trans.instant('comp.search-panel.excel-header.term2')?.toUpperCase();
      const oldNameLabel = this.trans.instant('comp.search-panel.excel-header.old-name')?.toUpperCase();
      const bufferIndexLabel = this.trans.instant('comp.search-panel.excel-header.buffer-index')?.toUpperCase();
      const fiberIndexLabel = this.trans.instant('comp.search-panel.excel-header.fiber-index')?.toUpperCase();
      const fiberOrderLabel = this.trans.instant('comp.search-panel.excel-header.fiber-order')?.toUpperCase();

      const deviceHeaders = [
        term1Label,
        oldNameLabel,
        traceLabel,
        fiberOrderLabel,
        bufferLabel,
        bufferIndexLabel,
        colorLabel,
        fiberIndexLabel,
        serviceLabel,
        bufferLabel,
        bufferIndexLabel,
        colorLabel,
        fiberIndexLabel,
        oldNameLabel,
        traceLabel,
        fiberOrderLabel,
        term2Label
      ];

      //Main data
      const filteredDeviceData = devices.map(device => ({
        mslink: device.deviceAttributes?.mslink || '',
        name: device.name,
        description: device.description
      }));


      this.deviceID = this.getDeviceID();
      await this.getConnectionFromDeviceID(this.deviceID);


      //External data
      const filteredDevicesWithConnectionsInfo = this.devicesWithConnections.map(device => {
        const connectionArray = device.connection || [];

        const getServiceName = (connectionItem) => {
          const serviceName1 = connectionItem.elementID1_Rel?.serviceName;
          const serviceName2 = connectionItem.elementID2_Rel?.serviceName;

          return [...new Set([serviceName1, serviceName2])]
            .filter((x) => !!x)
            .join(' ,');
        };

        return {
          name: device.name,
          data: connectionArray.map(connectionItem => ({
            term1: connectionItem.elementID1_Rel?.term1 || "",
            description1: connectionItem.elementID1_Rel?.T1[0]?.description || "",
            trace1: this.formatName(connectionItem.elementID1_Rel?.name || ""),
            fiberOrder1: connectionItem.elementID1_Rel?.fiberOrder || "",
            buffer1: connectionItem.elementID1_Rel?.fiberAttributes?.buffer || "",
            bufferIndex1: connectionItem.elementID1_Rel?.T1[0]?.BufferIndex || "",
            color1: connectionItem.elementID1_Rel?.fiberAttributes?.color || "",
            fiberIndex1: this.getFiberIndex(connectionItem.elementID1_Rel.T1[0]?.children, connectionItem.elementID1_Rel?.fiberID) || "",
            service1: getServiceName(connectionItem),

            color2: connectionItem.elementID2_Rel?.fiberAttributes?.color || "",
            fiberIndex2: this.getFiberIndex(connectionItem.elementID2_Rel.T2[0]?.children, connectionItem.elementID2_Rel?.fiberID) || "",
            buffer2: connectionItem.elementID2_Rel?.fiberAttributes?.buffer || "",
            bufferIndex2: connectionItem.elementID2_Rel?.T2[0]?.BufferIndex || "",
            trace2: this.formatName(connectionItem.elementID2_Rel?.name || ""),
            description2: connectionItem.elementID2_Rel?.T2[0]?.description || "",
            fiberOrder2: connectionItem.elementID2_Rel?.fiberOrder || "",
            term2: connectionItem.elementID2_Rel?.term2 || "",

          }))
        };
      });
      this.excelReportService.generateExcel(filteredDeviceData, containerHeaders, principalSheetName, 'ContainerWithAssociatedDevices', this.entity.name, deviceHeaders, filteredDevicesWithConnectionsInfo);
    } catch (error) {
      console.error('Error exporting data:', error);
    } finally {
      this.isLoading = false;
    }
  };

  getFiberIndex = (childrenString, fiberID) => {
    const children = JSON.parse('[' + childrenString + ']');
    return children.findIndex(child => child.fiberID === fiberID) + 1;
  }

  getDeviceID = (): number[] => {
    return this.ds_accordion_device[0].data.map((device: Device) => Number(device.deviceID));
  }

  async getConnectionFromDeviceID(deviceIDs: number[]) {
    this.devicesWithConnections = [];

    for (const id of deviceIDs) {
      try {
        const deviceResponse = await this.deviceService.getByID(id).toPromise();

        const connectionResponse = await this.connectionService.getByDeviceID(id, 'FIBER_FIBER').toPromise();
        if (connectionResponse) {
          deviceResponse.connection = connectionResponse;
          deviceResponse.connection.forEach(c => {
            if (!c.elementID1_Rel.fiberAttributes) {
              c.elementID1_Rel.fiberAttributes = JSON.parse(c.elementID1_Rel.fiberData);
            }
            if (!c.elementID2_Rel.fiberAttributes) {
              c.elementID2_Rel.fiberAttributes = JSON.parse(c.elementID2_Rel.fiberData);
            }
          });
          this.devicesWithConnections.push(deviceResponse);
        }
      } catch (error) {
        console.error(`Error al obtener datos para el dispositivo con ID ${id}:`, error);
      }
    }
  }
  getProjectDataDetailUserfld2Fields(): { key: string; value: string }[] {
    return Object.entries(this.projectDataDetailUserfId2 || {}).map(([key, value]) => ({
      key: this.capitalizeFirstLetter(key),
      value: value?.toString().trim() === '' ? this.trans.instant('general.no-data') : String(value)
    }));
  }

  capitalizeFirstLetter(text: string): string {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  getGroupedFields() {
    const fields = this.getProjectDataDetailUserfld2Fields();
    const groupedFields = [];

    for (let i = 0; i < fields.length; i += 2) {
      groupedFields.push(fields.slice(i, i + 2));
    }
    return groupedFields;
  }

  //Backend requires string container ID for SectionData search
  getTraceAsociatedWithContainerID() {
    this.showLoading = true;
    const containerIDString = this.containerID.toString();

    this.containerService.getTraceAsociatedWithContainerID(containerIDString).subscribe(
      (data) => {
        if (!data || data.length === 0) {
          this.showLoading = false;
        }

        this.traceData = this.processTraceData(data);
        this.showLoading = false;
      },
      (error) => {
        console.error('Error fetching trace data:', error);
        this.showLoading = false;
      }
    );
  }

  processTraceData(data: any[]): any[] {
    return data.map(trace => {
      let sectionDataParsed: any = {};
      try {
        sectionDataParsed = JSON.parse(trace.sectionData);
      } catch (e) {
        console.error('Error parsing sectionData:', e);
      }

      this.showLoading = false;

      return {
        ...trace,
        msLink: sectionDataParsed['mslink'] || 'N/A',
        name: trace.name || 'N/A',
        description: trace.description || 'N/A'
      };
    });


  }
  async openComponentInNewTab() {
    this.showLoading = true;
    const path = '/trace-diagram';
    await this.getFiberFromTraceID();

    const url = this.appRoutingService.generateUrl(path);
    this.appRoutingService.navigateTo(url, true);
  }

  async getFiberFromTraceID() {
    try {
      let traceData2List = [];

      if (Array.isArray(this.traceData)) {
        for (const trace of this.traceData) {
          const traceData2 = await firstValueFrom(this.traceService.getByID(trace.traceID));

          traceData2List.push(traceData2);
        }

        this.dataTransferService.setTraceData(traceData2List);
        this.dataTransferService.setName(this.name);
        this.dataTransferService.setPlatformID(this.platformID);

        this.showLoading = false;


      } else {
        console.error('traceData is not an array');
      }
    } catch (error) {
      console.error('Error retrieving trace data:', error);
    }
  }

  openCommentPopup(): void {
    this.isCommentPopupVisible = true;
  }

  formatName = (name: string) =>
    name.includes(' [') ? name.split(' [')[0] : name;

}

