import { Injectable } from '@angular/core';

import CustomStore from 'devextreme/data/custom_store';
import ArrayStore from 'devextreme/data/array_store';

import { Cons } from '../../cons/cons';
import { ContainerService } from '../map-elements-services/container.service';
import { DeviceService } from '../map-elements-services/device.service';
import { InfrastructureService } from '../map-elements-services/infrastructure.service';
import { ServiceService } from '../map-elements-services/service.service';
import { TraceService } from '../map-elements-services/trace.service';
import { EntityService } from '@app/@shared/model/aux-models/entity-service.type';
import { PlatformService } from '@app/auth/platform.service';
import { PolygonService } from '../map-elements-services/polygon.service';
import { ShapeService } from '../shape.service';

@Injectable()
export class DataSourceService {

  loading: boolean = false;

  allDevices = [];
  devicesDatasource: CustomStore;

  allInfrastructures = [];
  infrastructureDatasource: CustomStore;

  allTraces = [];
  traceDatasource: CustomStore;

  allContainers = [];
  containerDatasource: CustomStore;

  allServices = [];
  servicesDatasource: CustomStore;

  allPolygons = [];
  polygonsDatasource: CustomStore;

  allProjects = [];
  projectsDatasource: CustomStore;

  constructor(
    private containerService: ContainerService,
    private deviceService: DeviceService,
    private infraService: InfrastructureService,
    private traceService: TraceService,
    private serviceService: ServiceService,
    private platformService: PlatformService,
    private polygonService: PolygonService,
    private shapeService: ShapeService
  ) {
    this.devicesDatasource = this.createDatasource(
      ['name', 'description'],
      this.allDevices,
      this.deviceService,
      "OR",
      false,
      false
    );

    this.infrastructureDatasource = this.createDatasource(
      ['name'],
      this.allInfrastructures,
      this.infraService,
      undefined,
      false,
      false
    );

    this.traceDatasource = this.createDatasource(
      ['name'],
      this.allTraces,
      this.traceService,
      undefined,
      false,
      true // Apply duplicate removal
    );

    this.containerDatasource = this.createDatasource(
      ['name'],
      this.allContainers,
      this.containerService,
      undefined,
      false,
      false
    );

    this.servicesDatasource = this.createDatasource(
      ['name'],
      [],
      this.serviceService,
      undefined,
      true,
      false
    );

    this.polygonsDatasource = this.createDatasource(
      ['name'],
      this.allPolygons,
      this.polygonService,
      undefined,
      false,
      false
    );

    this.projectsDatasource = this.createDatasource(
      ['name'],
      this.allProjects,
      this.shapeService,
      undefined,
      false,
      false
    );
  }

  createDatasource(keyName: string[], store, service, clause?, useContains?: boolean, removeDuplicates?: boolean): CustomStore {
    let ds = new CustomStore({
      key: keyName[0],
      load: (loadOptions) => {
        const searchValue = loadOptions?.searchValue?.toLowerCase() || '';

        if (!loadOptions.take) {
          loadOptions.take = 100;
        }

        // Build the filter for the request to the service
        let filter = { platformID: this.platformService.platformID };
        if (searchValue) {
          keyName.forEach(x => {
            var attribute = x;
            var attributes = attribute.split('.');
            var targetProperty = attributes.pop();
            var targetObject = attributes.reduce((obj, prop) => {
              if (obj && obj.hasOwnProperty(prop)) {
                return obj[prop];
              } else {
                var newObj = {};
                obj[prop] = newObj;
                return newObj;
              }
            }, filter);

            if (targetObject) {
              targetObject[targetProperty] = searchValue;
            }
          });
        }

        this.loading = true;

        // Make the request to the service
        return service.getWithFilter(filter, loadOptions?.take, loadOptions?.skip, clause)
          .toPromise()
          .then((res) => {
            // Filter the data obtained from the service before returning it
            const filteredData = res.filter(item =>
              keyName.some(key => {
                if (useContains) {
                  return item?.[key]?.toLowerCase().includes(searchValue);
                } else {
                  return item?.[key]?.toLowerCase().startsWith(searchValue);
                }
              })
            );

            // Remove duplicates if required
            const uniqueData = removeDuplicates ? this.removeDuplicates(filteredData) : filteredData;

            if (store.length !== undefined) {
              store.push(...uniqueData);
            }
            this.loading = false;
            return {
              data: uniqueData,
              totalCount: uniqueData.length
            };
          })
          .catch((err) => {
            this.loading = false;
            return Promise.reject(err);
          });
      },
      byKey: (key) => {
        let filter: any = { platformID: this.platformService.platformID };
        keyName.forEach(x => {
          var attribute = x;
          var attributes = attribute.split('.');
          var targetProperty = attributes.pop();
          var targetObject = attributes.reduce((obj, prop) => {
            if (obj && obj.hasOwnProperty(prop)) {
              return obj[prop];
            } else {
              var newObj = {};
              obj[prop] = newObj;
              return newObj;
            }
          }, filter);

          if (targetObject) {
            targetObject[targetProperty] = key;
          }
        });

        return service.getWithFilter(filter, 500, 0)
          .toPromise()
          .then((res) => {
            store = new ArrayStore({
              key: keyName,
              data: res
            });
            return res;
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
    });

    return ds;
  }

  async preloadTraces(take: number = 100, skip: number = 0) {
    const traces: any[] = await this.traceDatasource.load({ take, skip });
    this.allTraces = this.removeDuplicates(traces);
  }

  async preloadDevices(take: number = 100, skip: number = 0) {
    this.allDevices = await this.devicesDatasource.load({ take, skip });
  }

  async preloadContainers(take: number = 100, skip: number = 0) {
    this.allContainers = await this.containerDatasource.load({ take, skip });
  }

  async preloadInfrastructures(take: number = 100, skip: number = 0) {
    this.allInfrastructures = await this.infrastructureDatasource.load({ take, skip });
  }

  async preloadPolygons(take: number = 100, skip: number = 0) {
    this.allPolygons = await this.polygonsDatasource.load({ take, skip });
  }
  

  getServiceByEntityName(entityName: string): EntityService {
    if (typeof entityName !== 'string') {
      entityName = (entityName as any)?.name;
      entityName = entityName.toUpperCase();
    }
    switch (entityName.toUpperCase()) {
      case Cons._TRACE: {
        return this.traceService;
      }
      case Cons._DEVICE: {
        return this.deviceService;
      }
      case Cons._INFRASTRUCTURE: {
        return this.infraService;
      }
      case Cons._CONTAINER: {
        return this.containerService;
      }
      case Cons._SERVICE: {
        return this.serviceService;
      }
      case Cons._POLYGON: {
        return this.polygonService;
      }
      case Cons._SHAPE: {
        return this.shapeService;
      }
      default: {
        return undefined;
      }
    }
  }

  private removeDuplicates(array: any[]): any[] {
    const unique = array.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.name === value.name
      ))
    );
    return unique;
  }
}
