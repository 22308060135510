<div id="accordion">
  <dx-accordion #accordion [dataSource]="[singleAccordionItem]" [collapsible]="true" [multiple]="false"
    [animationDuration]="300" [selectedItems]="[singleAccordionItem]" id="polygon_filter_accordion"
    style="background-color: #f8f8f8">

    <div *dxTemplate="let item of 'title'">
      <div class="main-secondary-text">
        <span>{{ item.title }}</span>
      </div>
    </div>

    <div *dxTemplate="let item of 'item'">
      <div class="accordion-body">
        <p class="subtitle">{{'comp.polygon-filter.sub-title' | translate}}</p>

        <div class="row">
          <div class="col-12">
            <div *ngFor="let element of entityOption" class="row">
              <div class="col-12">
                <mat-checkbox [(ngModel)]="element.selected" (change)="toggleAllSubOptions(element)"
                  class="parent-checkbox">
                  {{ element.name }}
                </mat-checkbox>
              </div>

              <div class="col-12" *ngIf="element.subOptions?.length">
                <div *ngFor="let subOption of element.subOptions" class="row">
                  <div class="col-12">
                    <mat-checkbox [(ngModel)]="subOption.selected" (change)="updateParentCheckbox(element)"
                      class="child-checkbox">
                      {{ subOption.name }}
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-12">
            <dx-select-box [dataSource]="polenoFilterOptions" displayExpr="name" valueExpr="value"
              (onValueChanged)="onNetworkTypeChange($event.value)"
              placeholder="{{'comp.search-panel.choose-network-type' | translate}}">
            </dx-select-box>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12 d-flex justify-content-end">
            <dx-button 
              class="search-button main-background-theme" 
              [text]="'general.search' | translate"
              [hint]="'general.search' | translate" 
              (click)="onSearchButtonClick()">
            </dx-button>
          </div>
        </div>
      </div>
    </div>
  </dx-accordion>
</div>