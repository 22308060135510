import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsService } from '@app/auth';
import { PlatformService } from '@app/auth/platform.service';
import { TranslateService } from '@ngx-translate/core';
import { OverlappedRingsService } from '@app/@shared/services/overlapped-rings.service';
import { firstValueFrom } from 'rxjs';
import { WindowService } from '@app/@shared/services/cordova/window.service';
import { UserFile } from '@app/@shared/model/userFile.model';
import notify from 'devextreme/ui/notify';
import { Platform } from '@app/@shared/model/platform.model';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  fillerNav: any = [];
  welcomeMessage = '';
  showAlertPopupGenerateReport: boolean = false;
  loading: boolean = false;
  overlappedRingsFile: UserFile;
  items: any[]
  showNetWorkReport: boolean = false;
  showConstructiveFeasibility: boolean = false;
  showAudit: boolean = false;

  constructor(
    private credentialsService: CredentialsService,
    private platformService: PlatformService,
    private trans: TranslateService,
    private router: Router,
    private windowService: WindowService,
  ) {

  }

  ngOnInit() {
    const name = this.credentialsService.decodeToken()?.['name'];
    this.welcomeMessage = this.trans.instant('general.hello') + ' ' + name + '!';
  }

  handleEmitShowNetWorkReport(event: any) {
    this.showNetWorkReport = event;
  }

  handleEmitShowConstructiveFeasibilityOutput(event: any) {
    this.showConstructiveFeasibility = event;
  }

  handleShowAudit(event: any){
    this.showAudit = event;
  }

}
