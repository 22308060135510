import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IRedirectSettings } from '@app/@shared/model/interface/iRedirect-settings';
import { WindowService } from '@app/@shared/services/cordova/window.service';
import { TranslateService } from '@ngx-translate/core';
import { InfrastructureODFComponent } from './infrastructure-odf/infrastructure-odf.component';
import { Infrastructure } from '@app/@shared/model/infrastructure.model';
import { Equipment } from '@app/@shared/model/equipment.model';
import { ExcelJSHelperService } from '@app/@shared/services/aux-services/excelJS-helper.service';
import { Workbook, Worksheet } from 'exceljs';
import { Device } from 'devextreme/core/devices';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as saveAs from 'file-saver';
import { GeneralUtilsService } from '@app/@shared/services/aux-services/general-utils.service';
import { ExcelReportService } from '@app/@shared/services/excel-report.service';

@Component({
  selector: 'infrastructure-detail',
  templateUrl: './infrastructure-detail.component.html',
  styleUrls: ['./infrastructure-detail.component.scss'],
})
export class InfrastructureDetailComponent {

  @ViewChild(InfrastructureODFComponent, { static: false }) infrastructureODFComponent: InfrastructureODFComponent;

  disableDownloadAndInspectData: boolean = false;
  ds_accordion_element = [];
  accordion_element_selected = [];

  infrastructureToDownload: Infrastructure = undefined;
  downloadingInfrastructureODF: boolean = false;

  ds_accordion_equipment = [];
  ds_accordion_equipment_selected = [];
  selectedEquipment: Equipment;

  _entity: any;
  projectDataDetail: any;
  projectDataDetailDescr2: any;
  @Input('entity')
  get entity() {
    return this._entity;
  }
  set entity(value) {
    if (value == this._entity) {
      return;
    }
    this._entity = value;

    this.projectDataDetail = value.infrastructureAttributes;
    this.projectDataDetailDescr2 = value.infrastructureAttributes.descr2;


    this.disableDownloadAndInspectData = !this._entity?.equipment?.length;
    this.loadEntity();
  }

  constructor(
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private trans: TranslateService,
    private windowService: WindowService,
    private excelJSHelperService: ExcelJSHelperService,
    public generalUtilsService: GeneralUtilsService,
    private excelReportService: ExcelReportService
  ) { }

  loadEntity() {
    //Close search panel
    this.ds_accordion_element = [];

    this.ds_accordion_element.push({
      title:
        String.prototype.upperFirstLetter(this.entity.entityName) +
        ' ' +
        this.trans.instant('general.review'),
      data: this.entity,
    });

    this.accordion_element_selected = this.ds_accordion_element;

    this.loadEquipmentAccordion();
  }

  loadEquipmentAccordion = () => {
    const equipments = this.entity.equipment;
    this.ds_accordion_equipment = [
      {
        title: this.trans.instant('comp.infrastructure-detail.equipment'),
        data: equipments,
      },
    ];
  };

  equipmentSelected = (event) => {
    const equipment: Equipment = event.data;
    this.selectedEquipment = equipment;
  };



  odfView() {
    const redirectSettings: IRedirectSettings = {
      path: `${this.router.url}/infrastructure/${this.entity.infrastructureID}`,
      newTab: true,
      external: false,
    };
    this.windowService.redirectTo(redirectSettings)
  }

  preparingDownload(infrastructure: Infrastructure) {
    this.infrastructureToDownload = infrastructure;
    this.downloadingInfrastructureODF = true;
  }

  async downloadInfrastructureODF() {
    if (!this.infrastructureODFComponent) {
      return;
    }

    this.changeDetector.detectChanges();

    try {
      await this.infrastructureODFComponent.downloadGraph();
    } catch (ex) {
      console.log('ex', ex);
    } finally {
      this.downloadingInfrastructureODF = false;
      this.infrastructureToDownload = undefined;
    }
  }

  exportInfrastructureData = async ($event) => {
    $event.cancel = true;
    const equipment: Equipment[] = this.ds_accordion_equipment[0].data;
    let infrastructureDataInfo;
    try {

      //Main headers
      const nameLabel = this.trans.instant('comp.search-panel.excel-header.name')?.toUpperCase();
      const descriptionLabel = this.trans.instant('comp.search-panel.excel-header.description')?.toUpperCase();

      const infrastructureHeaders = [
        nameLabel,
        descriptionLabel
      ];

      //Main data
      infrastructureDataInfo = equipment.map(equipment => {
        return {
          name: equipment.name || "",
          description: equipment.description || "",
        };
      });

      this.excelReportService.generateExcel(infrastructureDataInfo, infrastructureHeaders, 'Infrastructure', 'Infrastructure_' + `${this.entity.name}`);
    } catch (error) {
      console.error('Error exporting data:', error);
    } finally {
    }
  };

}
