import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DxButtonModule, DxDataGridModule, DxGalleryModule, DxLoadPanelModule, DxPopupModule } from 'devextreme-angular';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MainScreenComponent } from './main-screen.component';
import { OverlappingRingsModule } from '@app/general/home/overlapping-rings/overlapping-rings.module';
import { DeviceOccupationModule } from '../device-occupation/device-occupation.module';
import { ConstructiveFeasibilityComponent } from '@app/general/constructive-feasibility/constructive-feasibility/constructive-feasibility.component';
import { DxFileUploaderModule } from 'devextreme-angular';
import { GeneralModule } from "../../general.module";
import { DxRadioGroupModule } from 'devextreme-angular';
import { FormsModule } from '@angular/forms';
import { DxSelectBoxModule } from 'devextreme-angular';
import { DxTextBoxModule } from 'devextreme-angular';

@NgModule({
  declarations: [
    MainScreenComponent,
    ConstructiveFeasibilityComponent
  ],
  imports: [
    CommonModule,
    DxGalleryModule,
    RouterModule,
    DxButtonModule,
    TranslateModule,
    DxPopupModule,
    MatCardModule,
    DxLoadPanelModule,
    DxDataGridModule,
    FlexLayoutModule,
    OverlappingRingsModule,
    DeviceOccupationModule,
    DxFileUploaderModule,
    GeneralModule,
    DxRadioGroupModule,
    FormsModule,
    DxSelectBoxModule,
    DxTextBoxModule
],
  exports	: [MainScreenComponent, ConstructiveFeasibilityComponent]
})
export class MainScreenModule { }
