<div class="gallery-container row">
  <dx-load-panel [visible]="isSaving" message="{{ 'general.loading' | translate }}" [showIndicator]="true"></dx-load-panel>
  
  <div class="canvas-and-images col-md-8">
    <dx-gallery #gallery class="custom-gallery-height" [dataSource]="imageGallery" [loop]="true"
      [showNavButtons]="false" [showIndicator]="true" (onOptionChanged)="handleOptionChanged($event)"
      (onContentReady)="initializeCurrentIndex()" (onItemClick)="openImageInNewTab($event)">

      <ng-template dxTemplate="item" let-image="data" let-index="index">
        <div class="image-item" (click)="openImageInNewTab(image.imageSrc)">
          <img [src]="image.imageSrc" alt="Image {{index + 1}}" class="gallery-image" />
        </div>
      </ng-template>
    </dx-gallery>

    <div *ngIf="imageGallery.length > 0" class="delete-button" (click)="deleteImage()">
      <i class="dx-icon-trash"></i>
    </div>

    <div *ngIf="imageGallery.length > 0" class="download-button" (click)="downloadImage()">
      <i class="dx-icon-download"></i>
    </div>
  </div>
  
  <div class="comment-section col-md-4">
    <div class="comment-input-container">
      <textarea [(ngModel)]="textComment" placeholder="{{'common-odf.add.comment' | translate}}"></textarea>
      <dx-button (click)="saveComment()" class="button main-background-theme full-width">{{ 'common-odf.save.comment' | translate }}</dx-button>
    </div>
        
    <div class="commentArea">
      <div class="comment comment-level-1" *ngFor="let comment of comments">
        <div class="comment-header">
          <div class="avatar">{{ comment.initials }}</div>
        </div>
        <div>
          <div class="comment-label">
            <span class="comment-name">{{ comment.name }}</span>
            <span class="comment-time"> │ {{ formatTime(comment.creationDate) }}</span>
          </div>
          <div class="comment-content" [innerHTML]="convertLineBreaksToHtml(comment.content)"></div>
        </div>
      </div>
    </div>
    
    <div class="widget-container">
      <dx-button (click)="onAddImage()" class="button main-background-theme full-width">{{ 'infrastructure-odf.add-image' | translate }}</dx-button>
    </div>
  </div>
</div>
