import { Component, Input, OnInit } from '@angular/core';
import { Cons } from '@app/@shared/cons/cons';
import { GeneralUtilsService } from '@app/@shared/services/aux-services/general-utils.service';

@Component({
  selector: 'project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {

  @Input() projectDataDetail: any;
  @Input() projectDataEntityName: any;

  projectData = {
    createdBy: null,
    creationDate: null,
    editedBy: null,
    editionDate: null,
    updatedVersion: null
  };

  dataSource = [this.projectData];

  constructor(private generalUtilsService: GeneralUtilsService) { }

  ngOnInit(): void {
    this.mapProjectDataDetailToProjectData();
  }

  private mapProjectDataDetailToProjectData() {
  
    if (this.projectDataDetail) {
      this.projectData = this.mapProjectDetails(this.projectDataDetail);
  
      if (this.projectDataEntityName === Cons._TRACE) {
        this.swapCreationAndEditionDates();
      }
  
      this.updateDataSource();
    }
  }
  
  private mapProjectDetails(details: any): any {
    return {
      createdBy: this.generalUtilsService.getDisplayValueOrNoData(details.createdBy),
      creationDate: this.generalUtilsService.convertToDDMMYYYY(details.createDate),
      editedBy: this.generalUtilsService.getDisplayValueOrNoData(details.updatedBy),
      editionDate: this.generalUtilsService.convertToDDMMYYYY(details.updateDate),
      updatedVersion: this.generalUtilsService.getDisplayValueOrNoData(details.updatedVersion),
    };
  }

  private swapCreationAndEditionDates(): void {
    [this.projectData.creationDate, this.projectData.editionDate] =
      [this.projectData.editionDate, this.projectData.creationDate];
  }
  
  private updateDataSource(): void {
    this.dataSource = [this.projectData];
  }
  
  
}