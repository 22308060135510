import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@env/environment';
import {
  RouteReusableStrategy,
  ApiPrefixInterceptor,
  ErrorHandlerInterceptor,
  SharedModule,
} from '@shared';
import { AuthModule } from '@app/auth';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { GeneralModule } from './general/general.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TokenInterceptor } from './@shared/http/token.interceptor';
import { ExpiredTokenModalComponent } from './@shared/components/expired-token-modal/expired-token-modal.component';
import { TokenInventoryInterceptor } from './@shared/http/token-inventory.interceptor';
import { DxGalleryModule } from "devextreme-angular";
import { HomeModule } from './general/home/home.module';
import { AgmCoreModule } from '@agm/core';
import { NavigationMenuModule } from './general/home/navigation-menu/navigation-menu.module';
import { GoogleMapView } from '@shared/components/search-panel/google-map-view/google-map-view.component';
import { PolygonFilterComponent } from './@shared/components/search-panel/polygon-filter/polygon-filter/polygon-filter.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule, 
    RouterModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB6j7KhdIVwSaJ277XkrcrnpJZ2dRde564' 
    }),
    FlexLayoutModule,
    
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production,
    }),
    
    
   
    TranslateModule.forRoot(),
    NgbModule,
    SharedModule,
    ShellModule,
    GeneralModule,
    NavigationMenuModule,
    HomeModule,
    AuthModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
    DxGalleryModule,
    
  ],
  declarations: [
    AppComponent,
    ExpiredTokenModalComponent,
    
   
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInventoryInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
