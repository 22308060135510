import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook, Worksheet } from 'exceljs';
import { ExcelJSHelperService } from '@app/@shared/services/aux-services/excelJS-helper.service';
import { Connection } from '@app/@shared/model/connection.model';
import * as saveAs from 'file-saver';
import { ContainerService } from '@app/@shared/services/map-elements-services/container.service';
import { Container } from '@app/@shared/model/container.model';
import { forkJoin } from 'rxjs';
import { MapInteractionService } from '@app/@shared/services/map-elements-services/map-interaction.service';
import { GeneralUtilsService } from '@app/@shared/services/aux-services/general-utils.service';
import { ExcelReportService } from '@app/@shared/services/excel-report.service';

@Component({
  selector: 'trace-detail',
  templateUrl: './trace-detail.component.html',
  styleUrls: ['./trace-detail.component.scss'],
})
export class TraceDetailComponent implements OnDestroy {
  ds_accordion_element = [];
  ds_accordion_fiber = [];

  filterData: any[] = [];

  ignoreFirstResult: boolean = true;

  accordion_element_selected = [];
  isClientNameVisible: boolean = true;
  projectDataDetail: any;
  projectDataEntityName: any;
  traceSectionData: any;

  _entity: any;
  statusFiber: any;//{ fibersCant: number; fibersTraceCant: number; fibersPersent: number; };
  @Input('entity')
  get entity() {
    return this._entity;
  }
  set entity(value) {
    if (value == this._entity) {
      return;
    }
    this._entity = value;
    this.traceSectionData = value.sectionData;
    this.projectDataEntityName = value.entityName;
    this.projectDataDetail = value.sectionAttributes;
    const fecha_cao = value.sectionAttributes?.fecha_cao;
    if (fecha_cao != undefined) {
      this._entity.sectionAttributes.fecha_cao = this.generalUtilsService.convertToDDMMYYYY(fecha_cao);

    }
    this.getContainer_DuctIDfromTraceSectionData();
    this.loadEntity();
  }

  colorRanges: { color: string, min: number, max: number, colorText: string }[] = [
    { color: "#dd4444cc", colorText: "white", min: 80, max: 100 },//red
    { color: "#dd8944cc", colorText: "white", min: 50, max: 79 },//orange
    { color: "#ddc644cc", colorText: "white", min: 20, max: 49 },//yellow
    { color: "#62dd44cc", colorText: "white", min: 0, max: 19 }//green
  ];




  constructor(private trans: TranslateService, private excelJSHelperService: ExcelJSHelperService, private containerService: ContainerService, private mapInteractionService: MapInteractionService, public generalUtilsService: GeneralUtilsService, private excelReportService: ExcelReportService) { }



  ngOnDestroy(): void {
    this.changeAllContainerDuctToDimgray();
  }



  loadEntity() {
    // Close search panel
    this.ds_accordion_element = [];
    console.log(this.entity.fiber);
    let fibersAux: any[] = this.entity.fiber;
    const fibersCant: number = fibersAux.length;
    const fibersTraceCant: number = fibersAux.filter(obj => obj.serviceTrace.length > 0).length;

    let fibersPersentText: string = " ";

    // Check if there are fibers available before calculating percentage
    if (fibersCant > 0) {
      const fibersPersent = Math.floor((fibersTraceCant / fibersCant) * 100);
      fibersPersentText = `${fibersPersent} %`;
    }

    this.statusFiber = {
      fibersCant: fibersCant,
      fibersTraceCant: fibersTraceCant,
      fibersTraceCantDisp: fibersCant - fibersTraceCant,
      fibersPersentText: fibersPersentText,
      fibersPersent: fibersPersentText.trim() !== "" ? parseInt(fibersPersentText) : null,
      title: "comp.search-panels.availability"
    };

    console.log(this.statusFiber);

    this.ds_accordion_element.push({
      title:
        String.prototype.upperFirstLetter(this.entity.entityName) +
        ' ' +
        this.trans.instant('general.review'),
      data: this.entity,
    });

    this.accordion_element_selected = this.ds_accordion_element;

    this.isClientNameVisible = this.entity.fiber.some(x => x.fiberAttributes.clientName);

    this.ds_accordion_fiber = [];
    this.ds_accordion_fiber.push({
      title: this.trans.instant('comp.search-panel.fibers'),
      data: this.entity.fiber,
    });
    console.log(this.ds_accordion_fiber);
  }


  getBackgroundColor(fibersPersent: number): string {

    if (this.entity && this.entity.fiber && this.entity.fiber.length === 0) {
      return 'transparent'; // Return transparent if there are no fibers
    }

    const range = this.colorRanges.find(range => fibersPersent >= range.min && fibersPersent <= range.max);

    const color = range ? range.color : 'defaultColor';
    // Convert the color to RGBA with 0.4 opacity
    return this.hexToRgba(color, 0.4);
  }

  hexToRgba(hex: string, alpha: number): string {
    // Remove '#' if present
    hex = hex.replace(/^#/, '');

    let r, g, b;


    if (hex.length === 3) {

      r = parseInt(hex.charAt(0) + hex.charAt(0), 16);
      g = parseInt(hex.charAt(1) + hex.charAt(1), 16);
      b = parseInt(hex.charAt(2) + hex.charAt(2), 16);
    } else {

      r = parseInt(hex.substr(0, 2), 16);
      g = parseInt(hex.substr(2, 2), 16);
      b = parseInt(hex.substr(4, 2), 16);
    }


    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }



  getTextColor(fibersPersent: number): string {
    const range = this.colorRanges.find(range => fibersPersent > range.min && fibersPersent <= range.max);
    return range ? range.colorText : 'defaultColor';
  }

  exportTraceData = async ($event) => {
    $event.cancel = true;
    const connections: any[] = this.ds_accordion_element[0].data.fiber;
    let traceDataWithFiberInfo;
    try {

      //Main headers
      const traceHeaders = [
        this.trans.instant('comp.search-panel.excel-header.buffer')?.toUpperCase(),
        this.trans.instant('comp.search-panel.excel-header.color')?.toUpperCase(),
        this.trans.instant('comp.search-panel.excel-header.service')?.toUpperCase(),
        this.trans.instant('comp.search-panel.excel-header.fiber-order')?.toUpperCase()
      ];

      //Main data
      traceDataWithFiberInfo = connections.map(connection => {
        return {
          buffer: connection.fiberAttributes?.buffer || "",
          color: connection.fiberAttributes?.color || "",
          fiberOrder: connection.fiberOrder || "",
          service: connection.serviceTrace?.[0]?.service?.name || ""
        };
      });



      this.excelReportService.generateExcel(traceDataWithFiberInfo, traceHeaders, 'Trace', 'Trace_' + `${this.entity.name}`);
    } catch (error) {
      console.error('Error exporting data:', error);
    } finally {
    }
  };

  exportDuctDataAssociatedWithTrace = async ($event) => {
    $event.cancel = true;
    const connections: any[] = this.ds_accordion_element[0].data.fiber;
    let ductDataInfo;
    try {

      //Main headers
      const ductHeaders = [
        "MSLINK",
        this.trans.instant('comp.search-panel.excel-header.name')?.toUpperCase(),
      ];

      //Main data
      ductDataInfo = this.filterData.map(duct => {
        return {
          mslink: duct.containerAttributes.mslink,
          name: duct.name || ""
        }
      });

      this.excelReportService.generateExcel(ductDataInfo, ductHeaders, 'Ducts', 'DuctsAssociatedWith_' + `${this.entity.name}`, this.entity.name);
    } catch (error) {
      console.error('Error exporting data:', error);
    } finally {
    }
  };

  onRowClicked(event: any) {
    const rowData = event.data;
    const selectedColor = 'cyan';
    const defaultColor = 'dimgray';

    this.filterData.forEach(row => {
      if (row === rowData) {
        if (row.elementType?.specAttributes) {
          row.elementType.specAttributes.color = selectedColor;
        } else {
          row.elementType = { specAttributes: { color: selectedColor } };
        }
      } else {
        if (row.elementType?.specAttributes) {
          row.elementType.specAttributes.color = defaultColor;
        } else {
          row.elementType = { specAttributes: { color: defaultColor } };
        }
      }
    });

    this.filterData.forEach(row => {
      this.mapInteractionService.updateElementClicked([row]);
    });

    this.searchInMapContainerDuctSelected(rowData);

  }

  searchInMapContainerDuctSelected(data: any): void {
    this.mapInteractionService.searchClicked(data);
  }



  changeAllContainerDuctToDimgray() {
    const defaultColor = 'dimgray';

    this.filterData.forEach(row => {
      if (row.elementType?.specAttributes) {
        row.elementType.specAttributes.color = defaultColor;
      } else {
        row.elementType = { specAttributes: { color: defaultColor } };
      }
    });

    this.filterData.forEach(row => {
      this.mapInteractionService.updateElementClicked([row]);
    });
  }

  //Find ducts related to a trace 
  getContainer_DuctIDfromTraceSectionData() {
    try {
      this.filterData = [];
      const parsedData = JSON.parse(this.traceSectionData);

      if (parsedData && parsedData.containerid) {
        let containerID = parsedData.containerid
          .replace(/[\[\]]/g, '')
          .split(',')
          .map(Number);

        if (containerID.length > 0 && containerID.some(id => !isNaN(id))) {
          this.getContainer_DuctfromContainerID(containerID);
        }
      }
    } catch (error) {
      console.error('Error parsing sectionData:', error);
    }
  }

  getContainer_DuctfromContainerID(containerID: number[]) {
    containerID.forEach(id => {
      this.containerService.getByID(id).subscribe(
        (response) => {
          this.filterData.push(response);
        },
        (error) => {
          console.error('Error retrieving container data:', error);
        }
      );
    });
  }

}
