import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import notify from 'devextreme/ui/notify';

import { HorizontalTreeComponent } from '@app/@shared/components/d3/horizontal-tree/horizontal-tree.component';
import { D3HorizontalTreeData, LeafData } from '@app/@shared/model/d3/horizontal-tree.model';
import { Infrastructure } from '@app/@shared/model/infrastructure.model';
import { InfrastructureService } from '@app/@shared/services/map-elements-services/infrastructure.service';

import { D3GraphHelperService } from '@app/@shared/services/d3/download-d3-graph.service';
import { ServiceService } from '@app/@shared/services/map-elements-services/service.service';
import { ITraceabilityDiagram } from '@app/@shared/model/interface/iTraceability-diagram';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentPort } from '@app/@shared/model/equipmentPort.model';
import { ServiceTrace } from '@app/@shared/model/serviceTrace.model';
import { Service } from '@app/@shared/model/service.model';
import { Cons } from '@app/@shared/cons/cons';

interface InfrastructureGraph {
  fileName: string;
  graph: D3HorizontalTreeData,
  rendered: boolean,
}

@Component({
  selector: 'infrastructure-odf',
  templateUrl: './infrastructure-odf.component.html',
  styleUrls: ['./infrastructure-odf.component.scss'],
})

export class InfrastructureODFComponent implements OnInit {

  @ViewChildren(HorizontalTreeComponent) horizontalTreeComponents: QueryList<HorizontalTreeComponent>;

  /*
      This component can be rendered via URL, in that case we'll get the data from the resolvers inside the activatedRoute.
      But if this component is rendered inside HTML using the <infrastructure-odf> selector, we need to pass this Input to supply the missing information.
  */
  @Input() infrastructure: Infrastructure;
  @Output() componentReady = new EventEmitter<void>();

  @Output() portExtracted = new EventEmitter<string[]>(); // Emite un array de nombres extraídos

  loading: boolean = false;
  displayTraceabilityDiagramModal: boolean = false;

  traceabilityDiagramData: ITraceabilityDiagram;

  selectedTab: number = 0;
  svgIcons = new Map<string, string>();
  entityName = Cons._INFRASTRUCTURE;
  infrastructureID: number;
  infrastructureODFs: InfrastructureGraph[];
  showTraceability: boolean = true;

  portSVGs = [
    "../../../../../../../assets/icons/blue-square.svg",

    "../../../../../../../assets/icons/green-square.svg",

    "../../../../../../../assets/icons/blue-square-with-circle.svg",

    "../../../../../../../assets/icons/green-square-with-triangle.svg",

    "../../../../../../../assets/icons/buffer.svg",

    "../../../../../../../assets/icons/circle-with-cross.svg",

    "../../../../../../../assets/icons/empty-circle.svg",

    "../../../../../../../assets/icons/green-square-half.svg",

    "../../../../../../../assets/icons/red-square-half.svg",

    "../../../../../../../assets/icons/ethernet.svg",


  ];

  examplePortSVG = [

    "../../../../../../../assets/icons/black-square.svg",
    "../../../../../../../assets/icons/black-square-grey.svg",
    "../../../../../../../assets/icons/black-square-yellow.svg",



  ];


  svgLabels: string[] = ['SC-PC', 'SC-APC', 'LC-PC', 'LC-APC', 'FC-PC', 'FC-APC', 'ST', 'Euro 2000', 'EURO LX.5'];
  exampleLabel: string[] = [];
  infrastructureName: string;
  infrastructureDescription: string;
  infrastructureFullName: string;
  finishComponentLoading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private d3GraphHelperService: D3GraphHelperService,
    private infrastructureService: InfrastructureService,
    private serviceService: ServiceService,
    private translateService: TranslateService,

  ) {

    this.translateService.get([
      'infrastructure-odf.example-label.free-port',
      'infrastructure-odf.example-label.connected-cable-no-services',
      'infrastructure-odf.example-label.active-services',
      'infrastructure-odf.example-label.unassigned-port'
    ]).subscribe(translations => {
      this.exampleLabel = [
        translations['infrastructure-odf.example-label.free-port'],
        translations['infrastructure-odf.example-label.connected-cable-no-services'],
        translations['infrastructure-odf.example-label.active-services'],
        translations['infrastructure-odf.example-label.unassigned-port']
      ];

      this.svgLabels.push(translations['infrastructure-odf.example-label.unassigned-port']);


    });
  }



  async ngOnInit() {
    this.loading = true;
    await this.loadSVGIcons();
    const tokenFromActivatedRoute: string = this.activatedRoute.snapshot.queryParamMap.get('token');
    if (tokenFromActivatedRoute) {
      await this.resolveFromMslink();
    }
    else {
      await this.resolveFromInfrastructureID();
    }
    await this.getInfrastructureName();
    this.loading = false;
    this.finishComponentLoading = true;
  }

  async resolveFromMslink() {
    const mslinkFromActivatedRoute: string = this.activatedRoute.snapshot.paramMap.get('infrastructureID');

    if (mslinkFromActivatedRoute) {
      //this.infrastructureID = Number(infrastructureIDFromActivatedRoute);
    }
    this.activatedRoute.data.subscribe(async ({ infrastructure, infrastructureODF }: { infrastructure: Infrastructure, infrastructureODF: D3HorizontalTreeData[] }) => {

      this.infrastructureID = infrastructureODF[0].parentElement;
      this.infrastructureService.getByID(this.infrastructureID).subscribe(async (res) => {
        this.infrastructure = res;
        this.infrastructureODFs = this.generateInfrastructureODF(infrastructureODF);
        await this.loadServicesToFibers(infrastructureODF)
      });
    });
  }

  async resolveFromInfrastructureID() {
    const infrastructureIDFromActivatedRoute: string = this.activatedRoute.snapshot.paramMap.get('infrastructureID');

    if (infrastructureIDFromActivatedRoute) {
      this.infrastructureID = Number(infrastructureIDFromActivatedRoute);
    } else if (this.infrastructure) {
      // If this component is rendered via HTML (not via url), we'll get a infrastructure via Input()
      this.infrastructureID = this.infrastructure.infrastructureID;
    }

    this.activatedRoute.data.subscribe(async ({ infrastructure, infrastructureODF }: { infrastructure: Infrastructure, infrastructureODF: D3HorizontalTreeData[] }) => {
      // If this component is rendered via HTML (not via url), we don't get this data from the Resolver.
      if (!infrastructure && !infrastructureODF) {
        const graphData = await this.getInfrastructureODF(this.infrastructure.infrastructureID);
        this.infrastructureODFs = this.generateInfrastructureODF(graphData);

      } else {
        this.infrastructure = infrastructure;
        this.infrastructureODFs = this.generateInfrastructureODF(infrastructureODF);
        await this.loadServicesToFibers(infrastructureODF)
      }
    });
  }

  getInfrastructureODF(infrastructureID: number): Promise<D3HorizontalTreeData[]> {
    return firstValueFrom(this.infrastructureService.getODF(infrastructureID));
  }

  graphReady(infrastructureData: InfrastructureGraph) {
    infrastructureData.rendered = true;

    if (this.infrastructureODFs.every(x => x.rendered)) {
      this.componentReady.emit();
    }
  }

  generateInfrastructureODF(graphs: D3HorizontalTreeData[]) {
    return graphs.map((x, i) => ({ fileName: graphs.length > 1 ? `${this.infrastructure.name}-${i + 1}` : this.infrastructure.name, rendered: false, graph: x }));
  }

  loadSVGIcons = async () => {
    const iconsToImport = [
      'buffer',
      'fibra'
    ];

    this.svgIcons = await this.d3GraphHelperService.loadSVGIcons(iconsToImport);
  }

  loadServicesToFibers = async (data) => {
    // Extract distinct key-value pairs (fiberID, descr)
    const keyValuePairs = data
      .flatMap(item => item.children)
      .reduce((result, item) => {
        if (!result.some(pair => pair.fiberID === item.fiberID && pair.descr === item.descr)) {
          result.push({ fiberID: item.fiberID, descr: item.descr });
        }
        return result;
      }, []);

    this.infrastructure.equipment?.forEach(e => e.equipmentPort?.forEach((p: EquipmentPort) => {
      let st = new ServiceTrace();
      let keyvalue = keyValuePairs.find(s => s.fiberID == p.fiberID);
      if (keyvalue) {
        st.service = new Service();
        st.service.name = keyvalue.descr;
        p.fiber.serviceTrace = [st];
      }
    }));
  }

  downloadGraph() {
    const promises: Promise<void>[] = this.horizontalTreeComponents?.map(x => x?.downloadD3Graph());
    return Promise.all(promises);
  }

  async onLeafSelected(leafData: Partial<LeafData>) {
    const fiberID = leafData?.fiberID;
    const serviceID = leafData?.serviceID;

    if (!fiberID) {
      return;
    }

    if (!serviceID) {
      notify(this.translateService.instant('infrastructure-odf.traceability-diagram.error.fiber-has-no-service'), 'error', 4500);
      return;
    }

    this.loading = true;

    try {
      this.traceabilityDiagramData = await firstValueFrom(this.serviceService.getTraceabilityDiagramData(fiberID, serviceID, this.infrastructureID));
      this.displayTraceabilityDiagramModal = true;
    } catch (ex) {
      return;
    } finally {
      this.loading = false;
    }
  }

  async getInfrastructureName() {
    this.infrastructure = await firstValueFrom(this.infrastructureService.getByID(this.infrastructureID));
    this.infrastructureName = this.infrastructure.name;
    this.infrastructureDescription = this.infrastructure.description;
    this.infrastructureFullName = `${this.infrastructureName}` + ' ' + `(${this.infrastructureDescription})`;
  }


}
