import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { AppRoutingService } from "@app/app-routing.service";


@Component({
  selector: 'navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
})
export class NavigationMenuComponent implements OnInit {

  @Output() emitShowNetWorkReport = new EventEmitter<boolean>();
  @Output() showConstructiveFeasibilityOutput = new EventEmitter<boolean>();
  @Output() showAuditOutput = new EventEmitter<boolean>();


  showNetWorkReport = false;
  fillerNav: any = [];
  constructiveFeasibilityPath = '/home/constructive-feasibility';
  showConstructiveFeasibility = false;
  homePath = '/home';
  showAuditReport: boolean = false;
  networkReportPath = '/home/networkReport';

  constructor(
    private appRoutingService: AppRoutingService
  ) {
  }

  ngOnInit(): void {
    let routes = this.appRoutingService.getActiveRoutes();
    this.plainChildRoutes(routes);
    this.showAuditReport = true;
    this.showAuditOutput.emit(this.showAuditReport);

  }

  plainChildRoutes(routes) {
    routes.forEach(r => {
      if (r.children) {
        this.plainChildRoutes(r.children);
      }
      else {
        this.fillerNav.push(r);
      }
    });
  }

  navigateTo(path: string): void {
    this.resetStates();
  
    if (path === this.constructiveFeasibilityPath) {
      this.showConstructiveFeasibility = true;
    } else if (path === this.networkReportPath) {
      this.showNetWorkReport = true;
    } else {
      this.showAuditReport = true;
    }
  
    this.emitShowNetWorkReport.emit(this.showNetWorkReport);
    this.showConstructiveFeasibilityOutput.emit(this.showConstructiveFeasibility);
    this.showAuditOutput.emit(this.showAuditReport);
  
    this.appRoutingService.navigateTo(path);
  }
  
  private resetStates(): void {
    this.showNetWorkReport = false;
    this.showConstructiveFeasibility = false;
    this.showAuditReport = false;
  }

  show(item) {
    item.selected = true;
  }

  hide(item) {
    item.selected = false;
  }
}
