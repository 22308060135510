import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralUtilsService {

  constructor(private trans: TranslateService) { }


  getDisplayValueOrNoData(value: any): string {
    const stringValue = value !== null && value !== undefined ? String(value) : '';
    return stringValue.trim() !== '' ? stringValue : this.trans.instant('general.no-data');
  }

  convertToDDMMYYYY(dateInput: string) {
    if (dateInput) {
      const date = new Date(dateInput);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;

    } else {
      return this.trans.instant('general.no-data');
    }

  }
}
