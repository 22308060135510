import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HTMLtoSVGDownlaodService {
  private downloadingFiles = false;

  constructor(
    private router: Router,
  ) {}

  redirectUrl;

  /*
  SELECTED TAB:

  0 = IMAGEN DE BOX 1
  1 = IMAGEN DE BOX 2
  */

  async downloadAllSVGs(entity: any, selectedTab: number = 0) {
    if (!entity) return;
    this.downloadingFiles = true;

    // Start by constructing the URL without the device part
    this.redirectUrl = `${location.origin}${this.router.url}`.replace(/\/device\/[^/]+$/, '');

    // If 'container' is present, remove '/container/' and everything after it
    // This is done because when accessing SFN from portalGis, 
    // the URL segment related to "container" is not relevant
    this.redirectUrl = this.redirectUrl.replace(/\/container\/.*/, '');

    this.redirectUrl = `${this.redirectUrl}/device/${entity.deviceID}/${selectedTab}`;

    let iframe: HTMLIFrameElement;

    try {
        // Create iframe to load the device page
        iframe = document.createElement('iframe');
        iframe.style.width = '100%';
        iframe.style.height = '100%';
        iframe.src = this.redirectUrl;
        document.body.appendChild(iframe);

        await new Promise<void>((resolve) => {
            iframe.onload = () => resolve();
        });

        let svgElements = await new Promise<NodeListOf<SVGElement>>((resolve) => {
            const observer = new MutationObserver(() => {
                const svgs = iframe.contentWindow?.document.querySelectorAll('svg');
                if (svgs?.length > 0) {
                    observer.disconnect();
                    resolve(svgs);
                }
            });

            observer.observe(iframe.contentWindow!.document.body, { childList: true, subtree: true });
        });

        // Get styles from the document
        const styles = Array.from(iframe.contentWindow!.document.styleSheets)
            .map(styleSheet => {
                try {
                    return Array.from(styleSheet.cssRules)
                        .map(rule => rule.cssText)
                        .join('\n');
                } catch (e) {
                    console.warn(`Could not access stylesheet: ${styleSheet.href}`, e);
                    return '';
                }
            })
            .join('\n');

        for (let index = 0; index < svgElements.length; index++) {
            const svg = svgElements[index];
            const serializer = new XMLSerializer();
            const svgContent = serializer.serializeToString(svg);

            const scaleFactor = 1;
            const bbox = (svg as SVGGraphicsElement).getBBox();

            const styledSvgContent = `
                <svg xmlns="http://www.w3.org/2000/svg" 
                     width="${bbox.width * scaleFactor}" 
                     height="${bbox.height * scaleFactor}" 
                     viewBox="${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}">
                    <style>${styles}</style>
                    ${svgContent}
                </svg>
            `;

            const blob = new Blob([styledSvgContent], { type: 'image/svg+xml' });
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = `DEVICE_${entity.name}_image_${index + 1}.svg`;
            link.click();

            URL.revokeObjectURL(url);

            await new Promise(resolve => setTimeout(resolve, 100));
        }
    } catch (error) {
        console.error('An error occurred while downloading SVGs:', error);
    } finally {
        if (iframe) {
            document.body.removeChild(iframe);
        }
        this.downloadingFiles = false;
    }
}


  getDownloadingStatus() {
    return this.downloadingFiles;
  }
}
