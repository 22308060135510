import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { MainService } from './main.service';
import { plainToClass } from 'class-transformer';
import { Container } from '../model/container.model';
import { Device } from '../model/device.model';
import { Trace } from '../model/trace.model';
import { Infrastructure } from '../model/infrastructure.model';
import { Service } from '../model/service.model';
import { EntityService } from '../model/aux-models/entity-service.type';

@Injectable({
  providedIn: 'root'
})
export class SearchData {

  private selectBoxValue: any;

  constructor(private main: MainService,) { }

  setSelectBoxValue(value: any) {
    this.selectBoxValue = value;
  }

  getSelectBoxValue() {
    return this.selectBoxValue;
  }

  getByID(id: any, entityName : string): Observable<any> {
    this.main.chooseAPI(entityName);
    return this.main.getByID(entityName, id).pipe(
      map((res) => {
        try {
          const idName = Object.keys(res).filter(key => key.endsWith('ID') && key !== 'elementTypeID' && key !== 'platformID')[0];
          res.commonID = res[idName];
          res.entityName = entityName;

          switch (res.entityName.toLowerCase()) {
            case "container":
              return plainToClass(Container, res);
            case "device":
              return plainToClass(Device, res);
            case "trace":
              return plainToClass(Trace, res);
            case "infrastructure":
              return plainToClass(Infrastructure, res);
            case "service":
              return plainToClass(Service, res);
            default:
              return res;
          }
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }
}