import { Injectable } from '@angular/core';
import * as olProj from 'ol/proj';
import { Utilities } from '../main.service';
import { Feature } from 'ol';
import TileLayer from 'ol/layer/Tile';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import LineString from 'ol/geom/LineString';
import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import VectorSource from 'ol/source/Vector';
import { olMapPoint } from '@app/@shared/model/aux-models/ol-map-models/olMapPoint';
import { olMapLineString } from '@app/@shared/model/aux-models/ol-map-models/olMapLineString';
import { olMapPolygon } from '@app/@shared/model/aux-models/ol-map-models/olMapPolygon';
import { getCenter } from 'ol/extent';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import {
  Circle as CircleStyle,
  Fill,
  Icon,
  Stroke,
  Style,
  Text,
} from 'ol/style';
import { Cons } from '@app/@shared/cons/cons';

@Injectable({ providedIn: 'root' })
export class OlMapHelperService {
  constructor() { }

  OlMapPoint: olMapPoint = new olMapPoint();
  OlMapLineString: olMapLineString = new olMapLineString();
  OlMapPolygon: olMapPolygon = new olMapPolygon();
  public static _ID_DRAWINGLAYER = 'drawingLayer';

  // ********** FEATURE CREATION ************* //
  public createFeatureFromPoint(point: olMapPoint): Feature {
    let epsg3857 = this.transformFromLonLat(point.flatCoordinates);
    epsg3857 = Utilities.plainArray(epsg3857);

    let iconFeature = new Feature({
      geometry: new Point(epsg3857),
      //geometry: new Point(olProj.transform([x.lon, x.lat], 'EPSG:4326', new OSM().getProjection())),
      name: point.name,
      id: point.id,
      type: point.type,
      icon: point.icon,
      scale: point.scale,
      entity: point.entity,
      selected: point.selected,
      selectable: point.selectable,
      clusterFontColor: point.clusterFontColor,
      clusterFontBackground: point.clusterFontBackground
    });

    iconFeature.setId(point.id);

    return iconFeature;
  }

  createFeatureFromLineString(lineString: olMapLineString): Feature {
    try {
      if (!lineString || !lineString.flatCoordinates) {
        throw new Error('Invalid LineString object: flatCoordinates are undefined');
      }
      let epsg3857 = this.transformFromLonLat(lineString.flatCoordinates);
      return this.newLineStringInstance(epsg3857, lineString);
    } catch (error) {
      console.error('Error while creating a feature from LineString:', error);
      throw error;
    }
  }

  createFeaturesFromMultiLineString(lineString: olMapLineString): Feature[] {
    let epsg3857 = this.transformFromLonLat(lineString.flatCoordinates);
    let features: Feature[] = [];

    for (let i = 0; i < epsg3857.length; i++) {
      features.push(this.newLineStringInstance(epsg3857[i], lineString));
    }
    return features;
  }

  createFeatureFromPolygon(polygon: olMapPolygon): Feature {
    const epsg4326 = [...polygon.coordinateArray];
    let epsg3857 = this.transformFromLonLat(polygon.flatCoordinates);
    let geom = new Polygon([epsg3857]);
    polygon.centerExtent = { coordinates: getCenter(geom?.getExtent()), srID: Cons.SRIDS.S3857 };

    let feature = new Feature({
      geometry: geom,
      //geometry: new Point(olProj.transform([x.lon, x.lat], 'EPSG:4326', new OSM().getProjection())),
      name: polygon.name,
      id: polygon.id,
      type: polygon.type,
      entity: polygon.entity,
      selected: polygon.selected,
      color: polygon.color,
      width: polygon.width,
      background: polygon.background,
      coordinatesEPSG4326: epsg4326,
      popupEnable: polygon.popupEnable
    });

    return feature;
  }

  private newLineStringInstance(epsg3857, lineString): Feature {
    let line = new LineString(epsg3857);
    let lineStringFeature = new Feature({
      geometry: line,
      name: lineString.name,
      id: lineString.id,
      type: lineString.type,
      entity: lineString.entity,
      selected: lineString.selected,
      color: lineString.color,
      width: lineString.width,
    });

    return lineStringFeature;
  }

  getMapFeatureByID(context, entityId) {
    let vectorLayers = this.getMapVectorLayers(context);

    if (vectorLayers == undefined) {
      return undefined;
    }

    //vector index
    let index = vectorLayers.findIndex(l => l.getSource()?.getFeatures().some(f => f.getProperties()["id"] == entityId));

    if (index > -1) {
      //feature index
      let fIndex = vectorLayers[index].getSource()?.getFeatures().findIndex(f => f.getProperties()["id"] == entityId);

      if (fIndex > -1) {
        return vectorLayers[index].getSource()?.getFeatures()[fIndex];
      }
    }

    return undefined;
  }

  // ********** LAYER HANDLING ************* //
  createLayerWithIDAndSource(id: any, source?: VectorSource): VectorLayer {
    return new VectorLayer({
      id: id,
      //updateWhileAnimating: true,
      updateWhileInteracting: true,
      source: source ? source : new VectorSource(),
    });
  }

  createImageLayerWithIDAndSource(id: any, source?: VectorSource, declutter?): VectorImageLayer {
    return new VectorImageLayer({
      id: id,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      declutter: (declutter != undefined ? declutter : false),
      source: source ? source : new VectorSource(),
    });
  }

  createVectorLayer(): VectorLayer {
    return new VectorLayer({
      id: Utilities.getNewGuid(),
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });
  }

  getMapVectorLayers(context) {
    let mapLayers = context.map.getLayers().getArray().filter((l) =>
      l.getProperties()['id'] != OlMapHelperService._ID_DRAWINGLAYER &&
      !(l instanceof TileLayer)
    );
    if (!mapLayers || mapLayers.length == 0) {
      return [];
    }

    let vectorLayers = [];
    if (mapLayers instanceof Array) {
      mapLayers.forEach((lg) => {
        vectorLayers.push(...lg.getLayers().getArray().filter((v) => (v instanceof VectorLayer || v instanceof VectorImageLayer)));

        if (vectorLayers == undefined) {
          vectorLayers = [];
        }
      });
    } else {
      vectorLayers.push(
        mapLayers
          .getLayers()
          .getArray()
          .filter((v) => (v instanceof VectorLayer || v instanceof VectorImageLayer))
      );
    }

    return vectorLayers;
  }

  private getMapVectorLayersByID(context, id) {
    return this.getMapVectorLayers(context).filter(
      (x) => x.getProperties()['id'] == "VECTOR_" + id
    );
  }

  // ********** STYLE HANDLING ************* //
  newStyleInstance(opacity, scale, src): Style {
    return new Style({
      image: new Icon({
        anchor: [0.5, 1], // middle on the X axis, bottom on the Y axis
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        src: src ? src : '',
        opacity: opacity,
        //size: [70, 70],
        scale: scale,
      }),
    });
  }

  createStyleInstanceWithIcon(hover, size, src?, fontColor?, fontBackground?, scale?): Style {
    let text: Text;
    let textStyle: Style;
    let style;

    if (!hover) {
      style = this.newStyleInstance(0.7, scale, src);
    } else {
      style = this.newStyleInstance(1, scale, src);
    }

    if (size > 1) {
      let font = 12 + size / 10;
      if (font > 16) {
        font = 16;
      }

      if (!fontColor) {
        fontColor = '#444444';
      }

      if (!fontBackground) {
        fontBackground = '#f8fafa';
      }

      text = new Text({
        font: font + 'px sans-serif',
        text: size.toString(),
        fill: new Fill({
          color: fontColor,
        }),
      });

      let radius = 10 * (1 + size / 15);
      if (radius > 15) {
        radius = 15;
      }

      textStyle = new Style({
        image: new CircleStyle({
          radius: radius,
          opacity: !hover ? 1 : 0.75,
          stroke: new Stroke({
            color: fontColor,
          }),
          fill: new Fill({
            color: fontBackground,
          }),
        }),
        text: text,
        zIndex: 100,
      });

      return [style, textStyle];
    }

    return style;
  }

  setStyleForLineStringFeature(feature: Feature, selected: boolean, color?: string, width?: number, background?: string) {
    if (!color) {
      color = !selected ? 'blue' : 'orange';
    }

    if(feature.values_.entity.entityName == Cons._POLYGON){
      color = !selected ? 'rgb(119, 28, 90)' : 'orange';
    }

    if (!width || selected) {
      width = 5;
    }

    let fill;
    if (!background) {
      fill = new Fill({ color: 'rgba(0, 0, 255, 0.1)' });
    } else {
      fill = new Fill({ color: background });
    }

    let traceStyle = new Style({
      stroke: new Stroke({
        color: color,
        width: width,
      }),
      fill: fill,
      zIndex: 90,
    });

    feature.setStyle(traceStyle);
  }


  // ********** SYSTEM CONVERSION ************* //
  transformToLonLat(coordToTransform: any) {
    let rawCoordinates = coordToTransform;
    if (coordToTransform.coordinates) {
      rawCoordinates = coordToTransform.coordinates;
      coordToTransform.srID = Cons.SRIDS.S4326;
    }

    for (let i = 0; i < rawCoordinates.length; i++) {
      if (rawCoordinates[i] instanceof Array) {
        rawCoordinates[i] = this.transformToLonLat({ coordinates: rawCoordinates[i] });
      } else {
        let coordinates = [rawCoordinates[i], rawCoordinates[i + 1]];
        let transformed = olProj.transform(coordinates, 'EPSG:3857', 'EPSG:4326');

        if (transformed.coordinates) {
          rawCoordinates[i] = transformed.coordinates[0];
          rawCoordinates[i + 1] = transformed.coordinates[1];
        }
        else {
          rawCoordinates[i] = transformed[0];
          rawCoordinates[i + 1] = transformed[1];
        }
        i++;
      }
    }

    return coordToTransform;
  }

  transformToLonLatWithSystem(rawCoordinates: any, inputSrID: number) {
    for (let i = 0; i < rawCoordinates.length; i++) {
      if (rawCoordinates[i] instanceof Array) {
        rawCoordinates[i] = this.transformToLonLatWithSystem(rawCoordinates[i], inputSrID);
      } else {
        let coordinates = [rawCoordinates[i], rawCoordinates[i + 1]];
        i++;
        rawCoordinates = olProj.transform(coordinates, 'EPSG:' + inputSrID, 'EPSG:4326')
      }
    }

    return rawCoordinates;
  }

  transformFromLonLat(rawCoordinates: any) {
    if (!rawCoordinates || rawCoordinates.length == 0) {
      return [];
    }

    for (let i = 0; i < rawCoordinates.length; i++) {
      if (rawCoordinates[i] instanceof Array) {
        rawCoordinates[i] = this.transformFromLonLat(rawCoordinates[i]);
      } else {
        let coordinates = [rawCoordinates[i], rawCoordinates[i + 1]];
        i++;
        rawCoordinates = olProj.transform(coordinates, 'EPSG:4326', 'EPSG:3857')
      }
    }

    return rawCoordinates;
  }
}

export enum OpenLayerGeometryTypes {
  LINESTRING = 'LINESTRING',
  MULTILINESTRING = 'MULTILINESTRING',
  POINT = 'POINT',
  POLYGON = 'POLYGON',
}
