import { Injectable } from '@angular/core';
import { Workbook, Worksheet } from 'exceljs';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ExcelReportService {

  constructor() { }

  /**
 * Generates and exports an Excel file with multiple sheets.
 * @param data Main data to export, where each element represents a row.
 * @param headers Column headers for the main sheet.
 * @param sheetName Name of the main worksheet.
 * @param fileName Name of the Excel file to save.
 * @param titleHeader Optional title for the main sheet's header.
 * @param externalDataHeaders Optional headers for additional sheets.
 * @param externalData Optional array or object containing additional sheets' data.
 */

  generateExcel(data: any[], headers: string[], sheetName: string, fileName: string, titleHeader?: string, externalDataHeaders?: string[], externalData?: any[]) {
    const workbook = new Workbook();
    let subSheetName;
    let dataToWrite;

    // Create the main sheet with the provided data
    this.createSheet(workbook, sheetName, headers, data, titleHeader);

    // Create additional sheets if externalData is provided
    if (Array.isArray(externalData)) {
      externalData.forEach((dataSet, index) => {
        subSheetName = dataSet.name;
        dataToWrite = dataSet.data
        this.createSheet(workbook, subSheetName, externalDataHeaders, dataToWrite);
      });
    } else if (externalData) {
      subSheetName = (externalData as any).name;
      dataToWrite =  (externalData as any).data;
      this.createSheet(workbook, subSheetName, externalDataHeaders, dataToWrite);
    }

    // Export the file
    workbook.xlsx.writeBuffer().then((buffer) => {
      FileSaver.saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
    });
  }


  private createSheet(workbook: Workbook, sheetName: string, headers: string[], data: any[], titleHeader?: string) {
    const worksheet = workbook.addWorksheet(sheetName);

    this.addHeaders(worksheet, headers, titleHeader);

    this.addData(worksheet, data);

    this.adjustColumnWidths(worksheet);

    const startRow = titleHeader ? 2 : 1;

    this.centerTextExcel(worksheet, startRow, headers.length);
  }


  private addHeaders(worksheet: Worksheet, headers: string[], titleHeader?: string) {
    if (titleHeader) {
      const titleRow = worksheet.addRow([titleHeader]);
      titleRow.eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
      });
      worksheet.mergeCells(titleRow.number, 1, titleRow.number, headers.length);
    }

    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell, colNumber) => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF96C8FB' },
      };
    });
    worksheet.autoFilter = { from: { row: headerRow.number, column: 1 }, to: { row: headerRow.number, column: headers.length } };
  }

  
  private addData(worksheet: Worksheet, data: any[]) {
    data.forEach((rowObject, index) => {
      const rowData = Object.values(rowObject);
      const row = worksheet.addRow(rowData);

      if (index % 2 === 0) {
        row.eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFFFFF' }
          };
        });
      } else {
        row.eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFDCDCDC' }
          };
        });
      }
    });
  }


  private adjustColumnWidths(worksheet: Worksheet) {
    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : '';
        maxLength = Math.max(maxLength, cellValue.length);
      });
      column.width = maxLength + 2;
    });
  }

  private centerTextExcel(worksheet: Worksheet, startRow: number, totalColumns: number) {
    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      if (rowNumber >= startRow) {
        for (let i = 1; i <= totalColumns; i++) {
          row.getCell(i).alignment = { horizontal: 'center' };
        }
      }
    });
  }
  
}
