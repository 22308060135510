<dx-accordion #accordion_element [dataSource]="ds_accordion_element" [collapsible]="true" [multiple]="false"
  [animationDuration]="300" [selectedItems]="accordion_element_selected" id="accordion-element-container"
  style="background-color: #f8f8f8">
  <div *dxTemplate="let item of 'title'" [class.isDetail]="isDetailView">
    <h1 class="main-secondary-text">
      {{ item.title }}
    </h1>
    <div class="close-panel" (click)="closeDetail()" *ngIf="isDetailView">
      <i class="fas fa-times"></i>
    </div>
  </div>
  <div *dxTemplate="let item of 'item'">
    <div fxLayout="row wrap" class="row-clearance">
      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [(value)]="item.data.deviceAttributes.mslink" [disabled]="true" labelMode="floating"
          [label]="'general.bentley.mslink' | translate">
        </dx-text-box>
      </div>
      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [value]="generalUtilsService.getDisplayValueOrNoData(item.data.elementType?.name)" [disabled]="true" labelMode="floating"
          [label]="'general.model' | translate">
        </dx-text-box>
      </div>
    </div>
    <div fxLayout="row wrap" class="row-clearance">
      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [value]="generalUtilsService.getDisplayValueOrNoData(item.data?.name)" [disabled]="true" labelMode="floating"
          [label]="'general.name' | translate">
        </dx-text-box>
      </div>
      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [value]="generalUtilsService.getDisplayValueOrNoData(item.data?.description)" [disabled]="true" labelMode="floating"
          [label]="'general.description' | translate">
        </dx-text-box>
      </div>
    </div>
   
    <!--BENTLEY-->
    <div fxLayout="row wrap" class="row-clearance">

      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [value]="generalUtilsService.getDisplayValueOrNoData(item.data.deviceAttributes?.xfmID)" [disabled]="true" labelMode="floating"
          [label]="'general.bentley.xfmid' | translate">
        </dx-text-box>
      </div>

      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [value]="generalUtilsService.getDisplayValueOrNoData(item.data.deviceAttributes?.poleno)" [disabled]="true" labelMode="floating"
          [label]="'general.bentley.poleno' | translate">
        </dx-text-box>
      </div>

    </div>

    <div fxLayout="row wrap" class="row-clearance">


      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [value]="generalUtilsService.getDisplayValueOrNoData(item.data.deviceAttributes?.owner)" [disabled]="true" labelMode="floating"
          [label]="'general.bentley.owner' | translate">
        </dx-text-box>
      </div>

      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [value]="generalUtilsService.getDisplayValueOrNoData(item.data.deviceAttributes?.jobName)" [disabled]="true" labelMode="floating"
          [label]="'general.bentley.jobName' | translate">
        </dx-text-box>



      </div>

    </div>

    <div fxLayout="row wrap" class="row-clearance">


      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [value]="generalUtilsService.getDisplayValueOrNoData(item.data.deviceAttributes?.jobDesc)" [disabled]="true" labelMode="floating"
          [label]="'general.bentley.jobDesc' | translate">
        </dx-text-box>
      </div>

      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [value]="generalUtilsService.getDisplayValueOrNoData(projectDataDetailDescr2)" [disabled]="true" labelMode="floating"
          [label]="'descr2' | translate">
        </dx-text-box>
      </div>
    </div>





    <!---------------------------------->

    <div style="padding-top: 8px; text-align: right; padding-right: 6px;">
      <dx-button *ngIf="entity?.connection && entity.connection.length != 0" class="device-view me-2" icon="fas fa-file-download" [text]="'general.download' | translate"
        [hint]="'general.download' | translate" (click)="downloadAndGenerateImage()">
      </dx-button>
      <dx-button *ngIf="entity?.connection && entity.connection.length != 0" class="device-view" icon="far fa-eye" [text]="'general.inspect' | translate"
        [hint]="'general.inspect' | translate" (click)="deviceView()">
      </dx-button>
      <dx-button class="device-view me-2" icon="fas fa-comment" [hint]="'general.comment' | translate"
      (click)="openCommentPopup()">
    </dx-button>
    </div>
  </div>
</dx-accordion>

<br />
<dx-accordion *ngIf="entity?.connection && entity.connection.length != 0" [dataSource]="ds_accordion_connections"
  [collapsible]="true" [multiple]="false" [animationDuration]="300" [noDataText]="loading_connections ? '' : ''"
  [selectedItems]="ds_accordion_connections_selected" id="accordion-element-connection"
  style="background-color: #f8f8f8">
  <div *dxTemplate="let item of 'title'">
    <h1 class="main-secondary-text">
      {{ item.title }}
    </h1>
  </div>
  <div *dxTemplate="let item of 'item'">
    <dx-data-grid id="gridSplices" keyExpr="connectionID" [dataSource]="item.data" [remoteOperations]="false"
      [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showColumnLines]="false" [showBorders]="false"
      [columnAutoWidth]="true" [showColumnHeaders]="false"
      [noDataText]="'comp.device-detail.no-connections' | translate" [selectedRowKeys]="selectedConnectionKey"
      (onExporting)="exportDevice($event)" (onRowClick)="connectionRowClicked($event)" style="max-height: 38vh">
      <dxo-selection mode="single" *ngIf="connectionsAreSelectable"></dxo-selection>
      <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
      <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
      <dxi-column dataField="elementID1_Rel" caption="" cellTemplate="element1Template">
        <div *dxTemplate="let cellInfo of 'element1Template'" style="text-align: center; font-size: 0.9em">
          <div style="padding: 5px 0 5px 0">{{ cellInfo.value.name }}</div>
          <div fxLayout="row">
            <div fxFlex="50"
              [class]="'fiber-connection-chip left bk-' + cellInfo.value.fiberAttributes.buffer.toLowerCase()">
              {{ cellInfo.value.fiberAttributes.buffer }}
            </div>
            <div fxFlex="50"
              [class]="'fiber-connection-chip right bk-' + cellInfo.value.fiberAttributes.color.toLowerCase()">
              {{ cellInfo.value.fiberAttributes.color }}
            </div>
          </div>
        </div>
      </dxi-column>
      <dxi-column dataField="elementID2_Rel" caption="" cellTemplate="element2Template">
        <div *dxTemplate="let cellInfo of 'element2Template'" style="text-align: center; font-size: 0.9em">
          <div style="padding: 5px 0 5px 0">{{ cellInfo.value.name }}</div>
          <div fxLayout="row">
            <div fxFlex="50" [class]="
                'fiber-connection-chip left bk-' +
                cellInfo.value.fiberAttributes.buffer.toLowerCase()
              ">
              {{ cellInfo.value.fiberAttributes.buffer }}
            </div>
            <div fxFlex="50" [class]="
                'fiber-connection-chip right bk-' +
                cellInfo.value.fiberAttributes.color.toLowerCase()
              ">
              {{ cellInfo.value.fiberAttributes.color }}
            </div>
          </div>
        </div>
      </dxi-column>

      <dxo-export [enabled]="true"></dxo-export>
    </dx-data-grid>
  </div>
</dx-accordion>

<dx-accordion *ngIf="entity?.splitter && entity.splitter.length != 0 && !loading_connections" class="mt-4"
  [dataSource]="ds_accordion_splitter" [collapsible]="true" [multiple]="false" [animationDuration]="300"
  [selectedItems]="ds_accordion_splitter_selected" style="background-color: #f8f8f8">
  <div *dxTemplate="let item of 'title'">
    <h1 class="main-secondary-text">
      {{ item.title }}
    </h1>
  </div>
  <div *dxTemplate="let item of 'item'">
    <dx-data-grid id="splitter-list" [dataSource]="item.data" [remoteOperations]="false" [allowColumnReordering]="true"
      [rowAlternationEnabled]="true" [showColumnLines]="false" [showBorders]="false" [columnAutoWidth]="true"
      [noDataText]="'comp.device-detail.splitters.no-devices' | translate" (onRowClick)="splitterSelected($event)">
      <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
      <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
      <dxi-column dataField="name" dataType="string"></dxi-column>
      <dxi-column dataField="splitterType.name" dataType="string" caption="Splitter Type"></dxi-column>
      <dxi-column dataField="description" dataType="string"></dxi-column>
      <dxo-export [enabled]="true"></dxo-export>
    </dx-data-grid>

    <div style="padding-top: 8px; text-align: right; padding-right: 6px;"
    *ngIf="entity?.splitter && entity.splitter.length != 0">
    <dx-button class="device-view me-2" icon="fas fa-file-download" [text]="'general.download' | translate"
      [hint]="'general.download' | translate" (click)="prepareToDownloadSplitter()">
    </dx-button>
    <dx-button class="device-view" icon="far fa-eye" [text]="'general.inspect' | translate"
      [hint]="'general.inspect' | translate" (click)="splitterView()">
    </dx-button>
  </div>

  </div>
</dx-accordion>

<splitter-detail *ngIf="selectedSplitter" [splitter]="selectedSplitter" [closable]="true"
  (close)="selectedSplitter = undefined"></splitter-detail>

<dx-load-panel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#gridSplices' }" [(visible)]="loading_connections"
  [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>

<div hidden>
  <device-box *ngIf="deviceToDownload" [devices]="deviceToDownload" (componentReady)="downloadDevice()"></device-box>
  <splitter-box *ngIf="splittersToDownload" [device]="splittersToDownload"
    (componentReady)="downloadSplitters()"></splitter-box>
</div>

<dx-load-panel shadingColor="rgba(0,0,0,0.4)" [visible]="downloadingFiles" [showIndicator]="true" [showPane]="true"
  [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>



<div class="projectDetail">
  <project-detail [projectDataDetail]="projectDataDetail"></project-detail>
</div>

<dx-popup
  [(visible)]="isCommentPopupVisible"
  [width]="'70%'"
  [height]="'70%'"
  [showCloseButton]="true"
  [dragEnabled]="true"
  [closeOnOutsideClick]="true"
  title="Agregar Comentario">
  <app-odf-comment-section [id]="deviceID" [entityName]="entityName"></app-odf-comment-section>
</dx-popup>