import { Component, ViewChild, ElementRef, ViewChildren, QueryList, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { GalleryService } from '@app/@shared/services/aux-services/gallery.service';
import { GalleryImage } from '@app/@shared/model/gallery.model';
import * as DevExpress from 'devextreme/ui/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from '@app/@shared/services/aux-services/file.service';
import { firstValueFrom } from 'rxjs';
import DxLoadIndicator from 'devextreme/ui/load_indicator';
import { ResponseAPI } from '@app/@shared/model/responseApi.model';
import { PlatformService } from '@app/auth/platform.service';
import { DxGalleryComponent } from 'devextreme-angular/ui/gallery';
import { CredentialsService } from '@app/auth';
import { CommentModel } from '@app/@shared/model/comment.model';
import { CommentInterface } from '@app/@shared/model/interface/comment-interface';

@Component({
  selector: 'app-gallery-viewer',
  templateUrl: './gallery-viewer.component.html',
  styleUrls: ['./gallery-viewer.component.scss']
})
export class GalleryViewerComponent implements OnInit, OnChanges {
  @Input() mslink: number;
  @ViewChild('imageCanvas', { static: false }) imageCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChildren('canvasElement') canvasElements!: QueryList<ElementRef<HTMLCanvasElement>>;
  @ViewChild('gallery') gallery: DxGalleryComponent;

  isSaving: boolean = false;
  comments: CommentInterface[] = [];
  textComment: string = '';
  file: File | null = null;
  platformID: number | null = null;
  equipmentIDLocal: number;
  currentImageIndex: number;
  intervalId: any;
  currentIndex: number = 0;
  currentUserID: number | null = null;
  selectedImage: string = '';
  private readonly maxImages = 5;
  predefinedImages: GalleryImage[] = [];
  private imageCache: Map<string, HTMLImageElement> = new Map();
  imageGallery: GalleryImage[] = [];
  loadingImageIndex: number | null = null;
  mslinkCopy: number;

  private isInitialized = false;
  private initializationPromise: Promise<void> | null = null;

  constructor(
    private galleryService: GalleryService,
    private trans: TranslateService,
    private fileService: FileService,
    private platformService: PlatformService,
    private credentialsService: CredentialsService,
  ) { }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['mslink']?.currentValue !== undefined) {
      this.mslinkCopy = changes['mslink'].currentValue;
    }
  }

  async ngOnInit(): Promise<void> {
    this.initializationPromise = this.initializeComponent();
    await this.initializationPromise;
  }

  private async initializeComponent(): Promise<void> {
    try {
      this.platformID = await this.getPlatformIDAsync();
      this.currentUserID = await this.getCurrentUserIDAsync();
      await this.loadImages();
      this.isInitialized = true;
    } catch (error) {
      console.error('Error initializing component:', error);
      throw error;
    }
  }

  private async getPlatformIDAsync(): Promise<number> {
    return this.platformService.platformID;
  }

  private getCurrentUserIDAsync(): Promise<number | null> {
    const currentUserIDstring = this.credentialsService.decodeToken()?.['userID'];
    return Promise.resolve(currentUserIDstring ? parseInt(currentUserIDstring, 10) : null);
  }

  async loadImages(): Promise<void> {

    const mslink = Number(this.mslinkCopy);


    try {
      if (!this.mslink) {
        throw new Error('Mslink is not defined');
      }

      if (isNaN(mslink)) {
        throw new Error('MsLink is not a valid number');
      }

      const imageResponse = await firstValueFrom(
        this.fileService.getByElementID(mslink)
      );

      this.imageGallery = Array.isArray(imageResponse)
        ? imageResponse.map(image => ({
          fileID: image.fileID,
          imageSrc: image.filePath
        }))
        : [];

    } catch (error) {
      console.error('Error loading images:', error);
      this.imageGallery = [];
    }
  }

  async loadCommentByFileID(): Promise<void> {


    try {
      const fileID = this.getFileIDFromCurrentIndex();
      if (!fileID) return;

      const comments = await firstValueFrom(
        this.fileService.getCommentByFileID(fileID)
      );

      const processedComments = await this.processComments(comments);
      this.updateCommentsState(processedComments);
    } catch (error) {
      console.error('Error loading comments:', error);
    }
  }

  private async processComments(comments: any[]): Promise<CommentInterface[]> {
    return Promise.all(
      comments.map(async (item: any) => {
        return {
          ...item,
          initials: this.getInitials(item.user as string),
          content: String(item.description),
          name: item.user,
          creationDate: new Date(item.creationDate),
          eventID: item.eventID
        } as CommentInterface;
      })
    );
  }

  private updateCommentsState(commentsWithFileLinks: CommentInterface[]): void {
    this.comments = commentsWithFileLinks;
    this.comments.sort((a, b) => b.creationDate.getTime() - a.creationDate.getTime());
  }

  async onAddImage(): Promise<void> {
    try {
      if (!this.mslink && !this.mslinkCopy) {
        throw new Error('Required value for mslink is missing');
      }

      const elementId = this.mslinkCopy || this.mslink;

      if (this.imageGallery.length >= this.maxImages) {
        await this.showLimitReachedDialog();
        return;
      }

      const file = await this.selectFile();
      if (!file) return;

      await this.uploadFile(file, elementId);
      await this.loadImages();

    } catch (error) {
      console.error('Error in adding image process:', error);
    }
  }

  private async showLimitReachedDialog(): Promise<void> {
    const message = `<i>${this.trans.instant("general.limit-reached-description")}</i>`;
    const title = this.trans.instant("general.limit-reached");

    await DevExpress.custom({
      title,
      messageHtml: message,
      buttons: [{ text: this.trans.instant('general.ok'), onClick: () => { } }]
    }).show();
  }

  private selectFile(): Promise<File | null> {


    return new Promise((resolve) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.onchange = (event: any) => {
        resolve(event.target.files?.[0] || null);
      };
      input.click();
    });
  }

  private async uploadFile(file: File, elementId: number): Promise<void> {

    


    this.isSaving = true;
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('elementID', elementId.toString());
      formData.append('entityType', 'EQUIPMENT');

      await firstValueFrom(this.fileService.uploadFile(formData));
    } finally {
      this.isSaving = false;
    }
  }

  async saveComment(): Promise<void> {
    try {
      if (!this.mslinkCopy || !this.textComment.trim()) {
        throw new Error('Required data for comment is missing');
      }

      await this.uploadComment();
      this.textComment = '';
    } catch (error) {
      console.error('Error saving comment:', error);
    }
  }

  private async uploadComment(): Promise<void> {
    const fileID = this.getFileIDFromCurrentIndex();
    if (!fileID) return;

    this.isSaving = true;
    try {
      await firstValueFrom(
        this.fileService.uploadComment(
          this.textComment,
          this.mslinkCopy,
          this.platformID,
          fileID,
          this.currentUserID,
          "EQUIPMENT"
        )
      );
      await this.loadCommentByFileID();
    } finally {
      this.isSaving = false;
    }
  }

  async deleteImage(): Promise<void> {
    const fileID = this.getFileIDFromCurrentIndex();
    if (!fileID) return;

    const title = this.trans.instant("general.confirm-action");
    const message = `<i>${this.trans.instant("infrastructure-odf.delete.image")}</i>`;

    try {
      await DevExpress.custom({
        title,
        messageHtml: message,
        buttons: [
          {
            text: this.trans.instant('general.yes'),
            onClick: () => this.confirmDeleteImage(fileID)
          },
          {
            text: this.trans.instant('general.no'),
            onClick: () => { }
          }
        ]
      }).show();
    } catch (error) {
      console.error('Error confirming deletion:', error);
    }
  }

  async confirmDeleteImage(fileID: number): Promise<void> {
    if (!fileID) return;

    try {
      await firstValueFrom(this.fileService.removePhoto(fileID));
      await this.loadImages();
    } catch (error) {
      console.error('Error deleting the image:', error);
    }
  }

  async downloadImage(): Promise<void> {
    try {
      const fileURL = this.getFileURLFromCurrentIndex();
      if (!fileURL) {
        throw new Error('Could not retrieve the file URL');
      }
      await this.downloadFile(fileURL);
    } catch (error) {
      console.error('Error downloading the image:', error);
    }
  }

  private async downloadFile(fileUrl: string): Promise<void> {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  getInitials(name: string): string {
    const names = name.split(' ');
    return names.map(n => n.charAt(0).toUpperCase()).join('');
  }

  formatTime(time: Date): string {
    const adjustedTime = new Date(time.getTime() - (3 * 60 * 60 * 1000));
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };

    return new Intl.DateTimeFormat('es-AR', options)
      .format(adjustedTime)
      .replace(',', '');
  }

  convertLineBreaksToHtml(content: string): string {
    return content.replace(/\n/g, '<br>');
  }

  async getFileLink(fileID: number): Promise<{ filePath: string, fileName: string }> {
    try {
      const res: any = await firstValueFrom(this.fileService.getByID(fileID));
      return { filePath: res.filePath, fileName: res.name };
    } catch (error) {
      console.error('Error retrieving file:', error);
      throw error;
    }
  }

  private getFileIDFromCurrentIndex(): number | null {
    if (this.imageGallery.length > 0 && this.currentIndex !== null) {
      const image = this.imageGallery[this.currentIndex];
      return image.fileID;
    }
    return null;
  }

  private getFileURLFromCurrentIndex(): string | null {
    if (this.imageGallery.length > 0 && this.currentIndex !== null) {
      const image = this.imageGallery[this.currentIndex];
      return image.imageSrc;
    }
    return null;
  }

  async handleOptionChanged(event: any): Promise<void> {
    if (event.name === 'selectedIndex') {
      this.currentIndex = event.value;
      await this.loadCommentByFileID();
    }
  }

  async initializeCurrentIndex(): Promise<void> {
    this.currentIndex = 0;
    await this.loadCommentByFileID();
  }

  async openImageInNewTab(event: any): Promise<void> {
    let imageSrc = event.itemData.imageSrc;

    if (imageSrc.includes('dropbox.com')) {
      imageSrc = imageSrc
        .replace('?dl=1', '?raw=1')
        .replace('&dl=1', '&raw=1');
    }

    const link = document.createElement('a');
    link.href = imageSrc;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}