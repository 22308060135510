import { CardItem } from './card-item';
import { Component, Input, OnInit } from '@angular/core';
import { UserFile } from '@app/@shared/model/userFile.model';
import { WindowService } from '@app/@shared/services/cordova/window.service';
import { CredentialsService } from '@app/auth';
import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';
import { Container } from '@app/@shared/model/container.model';
import { firstValueFrom } from 'rxjs';
import { PlatformService } from '@app/auth/platform.service';

import { CardItemsService } from '@app/@shared/services/card-items.service';
import { MapSourceService } from '@app/@shared/services/map-elements-services/map-source.service';
import { ContainerService } from '@app/@shared/services/map-elements-services/container.service';
import { FileService } from '@app/@shared/services/aux-services/file.service';
import { OverlappedRingsService } from '@app/@shared/services/overlapped-rings.service';


@Component({
  selector: 'overlapping-rings',
  templateUrl: './overlapping-rings.component.html',
  styleUrls: ['./overlapping-rings.component.scss']
})

export class OverlappingRingsComponent implements OnInit {
  welcomeMessage = '';
  showAlertPopupGenerateReport: boolean = false;
  overlappedRingsFile: UserFile;


  @Input() cardItem: CardItem;
  containerFilePath: any;

  constructor(
    private trans: TranslateService,
    private credentialsService: CredentialsService,
    private overlappedRingsService: OverlappedRingsService,
    private windowService: WindowService,
    private cardItemsService: CardItemsService,
    private containerService: ContainerService,
    private fileService: FileService,
    private platformService: PlatformService
  ) {
    let date = new Date();
    date.setDate(date.getDate() - 1);
  }

  ngOnInit(): void {
    const name = this.credentialsService.decodeToken()?.['name'];
    this.welcomeMessage = this.trans.instant('general.hello') + ' ' + name + '!';

    this.loadCards();
  }

  loadCards() {
    this.cardItemsService.addCard(this.cardItem);
  }

  async generateNewReport() {

    this.showAlertPopupGenerateReport = false;
    try {
      await firstValueFrom(this.fileService.getDropboxReport(this.platformService.platformID.toString(), this.cardItem.endpoint));
    } catch (err) {
      console.error('Error al generar el reporte');
      notify(
        this.trans.instant('error.loading'),
        'error',
        4500
      );
    }
  }

  async downloadReport(cardItem) {

    try {
      if (cardItem.overlappedRingsFile.userFile.filePath) {
        this.windowService.redirectTo({
          path: cardItem.overlappedRingsFile.userFile.filePath,
          newTab: true,
          external: true,
        });
      } else {
        notify(
          this.trans.instant('overlapped-stats.home.btn.download-report.error-report-not-found'),
          'error',
          4500
        );
      }
    } catch (err) {
      console.error('Error al obtener el enlace de Dropbox:', err);
      notify(
        this.trans.instant('error.loading'),
        'error',
        4500
      );
    }
  }


  formatDate(date) {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false, // Use 24-hour format
    };

    return new Intl.DateTimeFormat('es-AR', options).format(new Date(date));
  }
}
