import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, Renderer2, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, firstValueFrom, forkJoin } from 'rxjs';
import { LocationData } from '@app/@shared/model/aux-models/locationData';
import { Feature } from 'ol';
import { OlMapComponent } from '@app/@shared/components/ol-map/ol-map.component';
import { MainService, Utilities } from '@app/@shared/services/main.service';
import { DataSourceService } from '@app/@shared/services/aux-services/datasources.service';
import { Cons } from '@app/@shared/cons/cons';
import { ContainerService } from '@app/@shared/services/map-elements-services/container.service';
import { DeviceService } from '@app/@shared/services/map-elements-services/device.service';
import { InfrastructureService } from '@app/@shared/services/map-elements-services/infrastructure.service';
import { TraceService } from '@app/@shared/services/map-elements-services/trace.service';
import { olMapPoint } from '@app/@shared/model/aux-models/ol-map-models/olMapPoint';
import { OlMapHelperService, OpenLayerGeometryTypes } from '@app/@shared/services/aux-services/ol-map-helper.service';
import { olMapLineString } from '@app/@shared/model/aux-models/ol-map-models/olMapLineString';
import { SearchPanelModel } from '@app/model/search-panel.model';
import { EntityService } from '@app/@shared/model/aux-models/entity-service.type';
import { UserSearch } from '@app/@shared/model/aux-models/user-search.model';
import { ICommonEntity } from '@app/@shared/model/interface/iCommonEntity';
import { PlatformService } from '@app/auth/platform.service';
import { Connection, ConnectionRel } from '@app/@shared/model/connection.model';
import html2canvas from 'html2canvas';
import { StaticReportContainer, StaticReportContainerSpec } from '@app/@shared/model/aux-models/staticReportContainer.model';
import { DxDrawerComponent } from 'devextreme-angular/ui/drawer';
import { SearchPanelComponent } from 'src/app/@shared/components/search-panel/search-panel.component';
import { GoogleMapView } from '@app/@shared/components/search-panel/google-map-view/google-map-view.component';
import { CommunicationService } from '@app/@shared/services/map-elements-services/communication.service';
import { TranslateService } from '@ngx-translate/core';
import { ITraceabilityDiagram, SingleNode } from '@app/@shared/model/interface/iTraceability-diagram';
import { MapInteractionService } from '@app/@shared/services/map-elements-services/map-interaction.service';
import apiSelector from 'src/environments/api-selector.json';
import { MapSourceService } from '@app/@shared/services/map-elements-services/map-source.service'
import { SharedViewService } from '@app/@shared/services/map-elements-services/sharedView.service';
import { environment } from '@env/environment';
import { SearchAreaService } from './../../@shared/services/map-elements-services/search-area.service';
import { PolygonDrawService } from '@app/@shared/services/map-elements-services/polygon-draw.service';
import { KJUR } from 'jsrsasign';
import cloneDeep from 'lodash.clonedeep';
import { PolygonService } from '@app/@shared/services/map-elements-services/polygon.service';
import { olMapPolygon } from '@app/@shared/model/aux-models/ol-map-models/olMapPolygon';
import WKT from 'ol/format/WKT';
import { Polygon } from '@app/@shared/model/interface/polygon-interface';
import { map } from 'rxjs/operators';


export interface OLDrawedPoints {
  preDrawnPoints: olMapPoint[];
  drawnPoints: olMapPoint[];
}

export interface OLDrawedLineStrings {
  preDrawnLineStrings: olMapLineString[];
  drawnLineStrings: olMapLineString[];
}

interface ServiceResponse {
  name: string;
}

@Component({
  selector: 'app-map-viewer',
  templateUrl: './map-viewer.component.html',
  styleUrls: ['./map-viewer.component.scss'],
})

export class MapViewerComponent implements OnInit, OnChanges {


  @ViewChild('mapContainer') mapContainer!: ElementRef;
  @ViewChild('drawer', { static: false }) drawer: DxDrawerComponent;
  @ViewChild('searchPanelRef', { static: false }) searchPanelRef: ElementRef<SearchPanelComponent>;
  @ViewChild(SearchPanelComponent) searchPanelComponent!: SearchPanelComponent;

  ds_layers = [
    {
      name: 'Container', subItems: [
        { name: 'LH', selected: false },
        { name: 'URB', selected: false }
      ]
    }
  ];

  selectedMainCheckbox: string | null = null;
  selectedSubCheckboxes: { mainItem: string, subItem: string }[] = [];
  searchByAreaLocal: boolean;

  // ------ OL MAP SETTINGS ------ //
  latitude: number;
  longitude: number;
  zoom: number;
  searchOneTrace = false;
  mapBoundaries: any = [];
  servicesSearchByUser: UserSearch[] = [];
  selectedService: UserSearch;
  selectedConnections: ConnectionRel[] = [];
  _points: olMapPoint[] = [];
  erase: boolean = true
  showOverflow: boolean = true;
  containerSearch: boolean;
  isContainerDuctSearch: boolean = false;
  traceSectionData: string;
  xygoMapSelected: boolean = false;
  googleMapSelected: boolean;
  openStreetMapSelected: boolean;
  responseTest: string;
  sharedViewButtonClicked: boolean = false;
  olDrawedLineStrings: OLDrawedLineStrings;
  olDrawedPoints: OLDrawedPoints;
  olDrawedLineStringsJSON: string;
  olDrawedPointsJSON: string;
  sharedViewShearchInProgress: boolean = false;
  showMaxServiceReachedModal: boolean = false;
  serviceFilterData: string = '';
  searchAreaCompleteSelected: boolean;
  searchAreaCorpSelected: boolean;
  searchAreaFtthSelected: boolean;
  polygonDrawCompleteSelected: boolean;
  polygonDrawCorpSelected: boolean;
  polygonDrawFtthSelected: boolean;
  searchingFromSearchPanel: boolean = false;
  isPopoverOfSharedViewVisible: boolean = false;
  searchSharedViewInProgress: boolean = false;
  polygonDataSource: any[] = [];
  totalPolygonsFromRes: any[] = [];
  showLayersPopupAlert: boolean = false;
  map: any;

  get points() {
    return this._points;
  }
  set points(value) {
    this._points = value;
    this.processDatasource();
  }

  groupedDatasource = [];
  // ------ OL MAP SETTINGS ------ //
  isSearchOpen = true;
  searchAreaInProgress = false;
  layersActivated = false;
  mapButtonClicked = false;
  searchAreaClicked = false;
  polygonDrawClicked = false;
  sharedView = false;
  dowloadImg = false;
  lineString: olMapLineString[] = [];
  searchPolygonInProgress = false;
  dataMapDrawing = [];
  dispatchCancel: any;
  isTextBoxFocused: boolean = false;
  selectedMapOption: string;
  selectedSearchAreaOption: string;
  selectedPolygonDrawOption: string;
  mapOptions = [{ name: 'Xygo' }, { name: 'Google' }, { name: 'Open Street' }];
  polenoFilterOptions = [{ name: this.trans.instant('general.all-network') }, { name: 'CORP' }, { name: 'FTTH' }];

  selectedElementsMap: any[] = [];
  textBoxValue: string = '';

  selectedEntityType: string[] = [];
  entityOption = [
    {
      id: 'container',
      name: 'Container',
      selected: true,
      subOptions: [
        { id: 'container_poste', name: 'Postes', filePath: 'container_poste.png', selected: true },
        { id: 'container_ducto_lh', name: 'Ductos LH', selected: true },
        { id: 'container_ducto_urb', name: 'Ductos URB', selected: true },
        { id: 'container_camara', name: 'Camaras', filePath: 'container_camara.png', selected: true },
      ],
    },
    {
      id: 'device',
      name: 'Device',
      selected: true,
      subOptions: [
        { id: 'device_empalme', name: 'Cajas de Empalme', filePath: 'device.png', selected: true },
        { id: 'device_cto', name: 'CTO', filePath: 'device_fibernode.png', selected: true },
      ],
    },
    {
      id: 'trace',
      name: 'Trace',
      selected: true,
      subOptions: [],
    },
    {
      id: 'infrastructure',
      name: 'Infrastructure',
      selected: true,
      subOptions: [
        { id: 'infrastructure_headend', name: 'Cabeceras', filePath: 'headend.png', selected: true },
        { id: 'infrastructure_cliente', name: 'Clientes (FO)', filePath: 'building.png', selected: true },
      ],
    },
  ];

  entityProvinceOption = [
    {
      name: 'Argentina',
      selected: false,
      indeterminate: false,
      subOptions: [
        { name: 'Buenos Aires', selected: false, countryAndProvince: 'AR-BUE' },
        { name: 'Catamarca', selected: false, countryAndProvince: 'AR-CAT' },
        { name: 'Chaco', selected: false, countryAndProvince: 'AR-CHA' },
        { name: 'Chubut', selected: false, countryAndProvince: 'AR-CHU' },
        { name: 'Córdoba', selected: false, countryAndProvince: 'AR-CBA' },
        { name: 'Corrientes', selected: false, countryAndProvince: 'AR-COR' },
        { name: 'Entre Ríos', selected: false, countryAndProvince: 'AR-ERI' },
        { name: 'Formosa', selected: false, countryAndProvince: 'AR-FOR' },
        { name: 'Jujuy', selected: false, countryAndProvince: 'AR-JUJ' },
        { name: 'La Pampa', selected: false, countryAndProvince: 'AR-LPA' },
        { name: 'La Rioja', selected: false, countryAndProvince: 'AR-LRI' },
        { name: 'Mendoza', selected: false, countryAndProvince: 'AR-MEN' },
        { name: 'Misiones', selected: false, countryAndProvince: 'AR-MIS' },
        { name: 'Neuquén', selected: false, countryAndProvince: 'AR-NEU' },
        { name: 'Río Negro', selected: false, countryAndProvince: 'AR-RNE' },
        { name: 'Salta', selected: false, countryAndProvince: 'AR-SAL' },
        { name: 'San Juan', selected: false, countryAndProvince: 'AR-SJU' },
        { name: 'San Luis', selected: false, countryAndProvince: 'AR-SLU' },
        { name: 'Santa Cruz', selected: false, countryAndProvince: 'AR-SCR' },
        { name: 'Santa Fe', selected: false, countryAndProvince: 'AR-SFE' },
        { name: 'Santiago del Estero', selected: false, countryAndProvince: 'AR-SES' },
        { name: 'Tierra del Fuego', selected: false, countryAndProvince: 'AR-TFU' },
        { name: 'Tucumán', selected: false, countryAndProvince: 'AR-TUC' },
      ]
    }
  ];

  entityFeederPolygons = [
    {
      name: this.trans.instant('general.all'),
      selected: false,
      indeterminate: false,
      subOptions: [
        { name: this.trans.instant('forms.map-viewer.under-permissions'), selected: false, status: 'EN PERMISOS' },
        { name: this.trans.instant('forms.map-viewer.under-deployment'), selected: false, status: 'EN DESPLIEGUE' },
        { name: this.trans.instant('forms.map-viewer.completed'), selected: false, status: 'FINALIZADO' },
      ]
    }
  ];


  get showCancelSearchButton() {
    return (
      this.searchAreaInProgress ||
      this.searchPolygonInProgress ||
      (this.dataMapDrawing && this.dataMapDrawing.length != 0) ||
      this.searchSharedViewInProgress
    );
  }

  selectedEntity;
  loading = false;
  coordinatesMap = [];
  dataProximityDetail: { dataAvailableServices: [{}], dataServicesNotAvailable: [{}] };
  showServiceInMap: { selectedRowData: [{}] };

  switchValue: boolean = false;
  dataToPass: ITraceabilityDiagram = { serviceName: '', traceability: [] };
  dataTraceabilityDiagram: ITraceabilityDiagram = null;
  sharedViewIcon = "../../../../../../../assets/icons/sharedViewIcon.png";
  generatedURL: string = '';
  urlPopupVisible: boolean = false;
  jwtEncode = environment.jwtEncode;

  constructor(
    private platformService: PlatformService,
    private containerService: ContainerService,
    private deviceService: DeviceService,
    private infraService: InfrastructureService,
    private traceService: TraceService,
    private olMapHelperService: OlMapHelperService,
    private datasources: DataSourceService,
    private mainService: MainService,
    private renderer: Renderer2,
    private communicationService: CommunicationService,
    private trans: TranslateService,
    private mapInteractionService: MapInteractionService,
    private mapSourceService: MapSourceService,
    private sharedViewService: SharedViewService,
    private polygonService: PolygonService,
    private searchAreaService: SearchAreaService,
    private polygonDrawService: PolygonDrawService,

  ) {
    this.dataProximityDetail =
    {
      dataAvailableServices: [{}],
      dataServicesNotAvailable: [{}]
    };
  }


  ngOnChanges(changes: SimpleChanges): void {

    this.showServiceInMap.selectedRowData;
  }

  @ViewChild('olMap') olMap: OlMapComponent;

  async ngOnInit() {
    this.selectedMapOption = this.mapOptions[0].name;
    this.selectedSearchAreaOption = this.polenoFilterOptions[0].name;
    this.selectedPolygonDrawOption = this.polenoFilterOptions[0].name;

    let initialPos = this.platformService.getPlatformPreference("initialPosition");

    if (initialPos) {
      this.latitude = initialPos.lat;
      this.longitude = initialPos.lon;
      this.zoom = initialPos.zoom;
    }
    if (initialPos) {
      this.latitude = initialPos.lat;
      this.longitude = initialPos.lon;
      this.zoom = initialPos.zoom;
    }
    else {
      this.getUserPosition().then(({ lon, lat, zoom }) => {
        this.latitude = lat;
        this.longitude = lon;
        this.zoom = zoom;
      },
        ((err) => {
          alert(err);
          this.latitude = -34;
          this.longitude = -58;
          this.zoom = 6;
        }));


      if (this.searchPanelRef) {
        const eventData = {};
        this.searchPanelRef.nativeElement.onTextBoxFocusIn(eventData);
      }
    }

    if (this.searchPanelRef && this.searchPanelRef.nativeElement) {
      const searchPanel = this.searchPanelRef.nativeElement;
      const focusState = true;
      if (focusState) {
        this.renderer.addClass(searchPanel, 'no-scroll');
      } else {
        this.renderer.removeClass(searchPanel, 'no-scroll');
      }
    }

    this.communicationService.areaExtent$.subscribe(areaExtent => {
      this.onAreaExtentCalculated(areaExtent);
    });

    this.communicationService.location$.subscribe((location: google.maps.LatLng) => {
      this.goToLastPointPosition(location);
    });

    this.communicationService.functionCall$.subscribe((param: ITraceabilityDiagram) => {
      this.dataTraceabilityDiagram = param;
      this.validateDataTraceabilityDiagram(param);
    });

    this.mapInteractionService.elementClicked$.subscribe(rowData => {
      if (rowData) {
        this.lineDataLoad(rowData, true);
      }
    });

    this.mapInteractionService.searchClicked$.subscribe(rowData => {
      if (rowData) {
        this.drawSingleElement(rowData, true);
      }
    });
  }



  getUserPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp =>
        resolve({ lon: resp.coords.longitude, lat: resp.coords.latitude, zoom: 8 }),
        err => {
          //Geolocation didn't work. Set some harcoded position
          return resolve({ lon: -58.3850703, lat: -34.604369, zoom: 4 })
        }
      );
    });
  }

  async getLayer(layerName: string): Promise<void> {
    this.loading = true;

    let results: StaticReportContainer[] = [];

    try {
      if (layerName === 'Container') {
        const [lhResult, urbResult] = await Promise.all([
          firstValueFrom(this.containerService.getFixedDuct(this.platformService.platformID, "LH")),
          firstValueFrom(this.containerService.getFixedDuct(this.platformService.platformID, "URB"))
        ]);
        results = [lhResult, urbResult];
      } else if (layerName === 'LH') {
        const lhResult = await firstValueFrom(
          this.containerService.getFixedDuct(this.platformService.platformID, "LH")
        );
        results = [lhResult];
      } else if (layerName === 'URB') {
        const urbResult = await firstValueFrom(
          this.containerService.getFixedDuct(this.platformService.platformID, "URB")
        );
        results = [urbResult];
      }

      for (const res of results) {
        await this.drawFixedDucts(res.reportAttributes, layerName);
      }
    } catch (error) {
      console.error('Error fetching layer data:', error);
    } finally {
      this.loading = false;
    }
  }

  async getPolygon(cityName: string) {
    this.loading = true;
  }

  async drawFixedDucts(res: StaticReportContainerSpec[], layerName: string): Promise<void> {
    let lineDS: olMapLineString[] = [];

    for (const x of res) {
      if (!lineDS.some((t1) => t1.id === x.containerID)) {
        let c = x?.specAttributes?.color;
        if (!c) {
          c = Cons.OLSTYLES.$traceDefaultColor;
        }

        const feature = await this.olMapHelperService.OlMapLineString.toMapFeature(x, layerName, c);
        lineDS.push(feature);
      }
    }

    this.lineString = [...this.lineString, ...lineDS];
  }


  async drawPolygon() {
    this.loading = true;
    try {
      if (!this.polygonDataSource) {
        this.polygonDataSource = [];
      }

      for (let i = 0; i < this.totalPolygonsFromRes.length; i++) {
        const polygon = this.totalPolygonsFromRes[i];
        try {
          const olPolygon = this.processPolygonData(polygon);

          if (olPolygon) {
            this.polygonDataSource.push(olPolygon);
          } else {
            console.error('The geometry format is not a valid polygon:', polygon.ogc_geometry_wkt);
          }
        } catch (polygonError) {
          console.error('Error processing a polygon:', polygon, polygonError);
        }
      }

      this.olMap.renderPolygons();
    } catch (error) {
      console.error('Error while fetching the polygons:', error);
    } finally {
      this.loading = false;
    }
  }


  private processPolygonData(polygon: Polygon): any | null {
    try {
      const geometry = this.readWktGeometry(polygon.geom_wkt);

      if (geometry && geometry.getType() === 'Polygon') {
        const formattedCoordinates = this.formatPolygonCoordinates(geometry.getCoordinates());
        return this.createOlPolygon(polygon, formattedCoordinates);
      }

      if (geometry && geometry.getType() === 'MultiPolygon') {
        const formattedCoordinates = geometry.getCoordinates().map((coords: number[][][]) =>
          this.formatPolygonCoordinates(coords)
        );
        return this.createOlMultiPolygon(polygon, formattedCoordinates);
      }

    } catch (error) {
      console.error('Error reading or processing WKT geometry:', polygon.geom_wkt, error);
    }

    return null;
  }

  private createOlMultiPolygon(polygon: Polygon, coordinates: number[][][]): any {
    const polygonEntity = {
      id: polygon.boundaryID || 'unknown_id',
      name: polygon.name || 'unknown_name',
      type: 'unknown_type',

    };

    const borderColor = polygon.color ? this.darkenColor(polygon.color) : 'rgb(119, 28, 90)';

    return {
      id: polygon.boundaryID || 'unknown_id',
      color: borderColor,
      width: 2,
      background: polygon.color || 'rgba(128, 128, 128, 0.5)',
      flatCoordinates: coordinates.flat(),
      visible: true,
      selected: false,
      centerExtent: undefined,
      name: polygon.name || 'unknown_name',
      type: 'unknown_type',
      entity: polygonEntity,
      locationData: undefined,
      coordinateArray: coordinates,
    };
  }


  private readWktGeometry(wkt: string): any {
    const wktFormat = new WKT();
    return wktFormat.readGeometry(wkt);
  }


  private formatPolygonCoordinates(coordinates: number[][][]): number[][] {
    return coordinates[0].map((coord) => [coord[0], coord[1]]);
  }


  private createOlPolygon(polygon: Polygon, coordinates: number[][]): any {
    const polygonEntity = {
      id: polygon.boundaryID || 'unknown_id',
      name: polygon.name || 'unknown_name',
      type: 'unknown_type',
      coordinatesEPSG4326: [...coordinates],
      entityName: Cons._POLYGON,
      commonID: polygon.boundaryID
    };

    const borderColor = polygon.color ? this.darkenColor(polygon.color) : 'rgb(119, 28, 90)';

    return {
      id: polygon.boundaryID || 'unknown_id',
      color: borderColor,
      width: 2,
      background: polygon.color || 'rgba(128, 128, 128, 0.5)',
      flatCoordinates: coordinates,
      visible: true,
      selected: false,
      centerExtent: undefined,
      name: polygon.name || 'unknown_name',
      type: 'unknown_type',
      entity: polygonEntity,
      locationData: undefined,
      coordinateArray: coordinates,
      popupEnable: true,
    };
  }

  private darkenColor(color: string): string {
    const rgba = color.match(/\d+(\.\d+)?/g);
    if (rgba && rgba.length === 4) {
      const r = Math.max(0, parseInt(rgba[0], 10) - 80);
      const g = Math.max(0, parseInt(rgba[1], 10) - 80);
      const b = Math.max(0, parseInt(rgba[2], 10) - 80);
      const a = parseFloat(rgba[3]);
      return `rgba(${r}, ${g}, ${b}, ${a})`;
    }
    return color;
  }

  toggleDrawer() {
    this.isSearchOpen = !this.isSearchOpen;
  }

  processDatasource() {
    this.groupedDatasource = [];
  }

  mapClicked($event) {
    let entity = this.handleClickOnElement($event);
    if (!this.switchValue) {
      if (!$event.data) {
        return;
      }

      if (!entity) {
        return;
      }

      let service = this.datasources.getServiceByEntityName(entity.entityName);
      if (!service) {
        return;
      }
      service.getByID(entity.commonID).subscribe((res) => {
        if (res) {
          this.selectedEntity = res;
          this.isSearchOpen = true;
        }
      });
    } else {
      let groupName = entity.elementType.groupID;
      if (groupName == 'DEVICE' || groupName == 'INFRASTRUCTURE') {
        this.selectedElementsMap.push(entity);
        this.selectedElementsMap = this.removeDuplicates(this.selectedElementsMap, `${groupName.toLowerCase()}ID`);
        if (this.selectedElementsMap.length >= 2 && this.dataTraceabilityDiagram != null) {
          this.loading = true;
          if (this.selectedElementsMap.length > 2)
            this.selectedElementsMap.splice(0, 1);

          this.validateDataTraceabilityDiagram(this.dataTraceabilityDiagram);
        }
      }
    }
  }

  handleClickOnElement($event) {
    if ($event.data?.length > 1) {
      let newZoom = 14;
      if (this.olMap.zoom >= 18) {
        this.processDatasource();
      } else {
        if (this.olMap.zoom >= newZoom) {
          newZoom = this.olMap.zoom;
          newZoom++;
        }

        let coord;
        if (Array.isArray($event.data)) {
          coord = $event.data[0].getProperties()['geometry'].flatCoordinates;
        } else {
          coord = $event.data.getProperties()['geometry'].flatCoordinates;
        }
        let lonlat = this.olMapHelperService.transformToLonLat(coord);

        this.olMap.moveTo(lonlat[1], lonlat[0], newZoom);
        this.olMap.setDefaultSelection(this.olMap);
      }
      return;
    }

    let entity;
    if (Array.isArray($event.data)) {
      entity = $event.data[0].getProperties()['entity'];
    } else {
      entity = $event.data?.getProperties()?.['entity'];
    }

    return entity;
  }


  elementHovered($event) { }

  mapZoomed($event) {
    if ($event.zoom && $event.zoom < 18) {
      if (this.points.some((x) => x.visible == false) && !this.selectedSubCheckboxes) {
        this.points.forEach((x) => (x.visible = true));
        this.processDatasource();
      }
    }


    if ($event.extentView) {
      //Coordinates should be grouped as x: long, y: lat
      this.mapBoundaries = [
        { x: $event.extentView[0], y: $event.extentView[1] }, //coord 1
        { x: $event.extentView[2], y: $event.extentView[1] }, //coord 2
        { x: $event.extentView[2], y: $event.extentView[3] }, //coord 3
        { x: $event.extentView[0], y: $event.extentView[3] }, //coord 4
      ];

      //Repeat first coord to close the polygon
      this.mapBoundaries.push(this.mapBoundaries[0]);
    }
  }

  selectLayers() {
    this.layersActivated = !this.layersActivated;
  }

  selectMap() {
    this.selectedMapOption = this.mapOptions[this.mapSourceService.mapSource].name;
    this.mapButtonClicked = !this.mapButtonClicked;
  }

  selectMapOption(event: any, optionName: string) {
    if (optionName == "Xygo") {
      this.xygoMapSelected = true;
      this.googleMapSelected = false;
      this.openStreetMapSelected = false;

    } else if (optionName == "Google") {
      this.xygoMapSelected = false;
      this.googleMapSelected = true;
      this.openStreetMapSelected = false;

    } else if (optionName == "Open Street") {
      this.xygoMapSelected = false;
      this.googleMapSelected = false;
      this.openStreetMapSelected = true;
    }
  }

  selectSearchAreaOption(event: any, optionName: string) {
    if (optionName == this.polenoFilterOptions[0].name) {
      this.searchAreaCompleteSelected = true;
      this.searchAreaCorpSelected = false;
      this.searchAreaFtthSelected = false;

    } else if (optionName == this.polenoFilterOptions[1].name) {
      this.searchAreaCompleteSelected = false;
      this.searchAreaCorpSelected = true;
      this.searchAreaFtthSelected = false;

    } else if (optionName == this.polenoFilterOptions[2].name) {
      this.searchAreaCompleteSelected = false;
      this.searchAreaCorpSelected = false;
      this.searchAreaFtthSelected = true;
    }
  }

  selectPolygonDrawOption(event: any, optionName: string) {
    if (optionName == this.polenoFilterOptions[0].name) {
      this.polygonDrawCompleteSelected = true;
      this.polygonDrawCorpSelected = false;
      this.polygonDrawFtthSelected = false;

    } else if (optionName == this.polenoFilterOptions[1].name) {
      this.polygonDrawCompleteSelected = false;
      this.polygonDrawCorpSelected = true;
      this.polygonDrawFtthSelected = false;

    } else if (optionName == this.polenoFilterOptions[2].name) {
      this.polygonDrawCompleteSelected = false;
      this.polygonDrawCorpSelected = false;
      this.polygonDrawFtthSelected = true;
    }
  }

  sharedViewSelected() {
    this.sharedViewShearchInProgress = true;
    this.sharedView = !this.sharedView;
  }

  async confirmSelection() {
    await this.searchByPolygon();
    this.sharedView = false;

  }

  async confirmLayerSelection() {

    this.loading = true;

    const containersSelected = this.ds_layers.some(layer =>
      layer.subItems.some(subItem => subItem.selected)
    );

    const polygonsSelected = this.entityProvinceOption.some(option =>
      option.subOptions.some(subOption => subOption.selected)
    );

    const feederPolygonsSelected = this.entityFeederPolygons.some(option =>
      option.subOptions.some(subOption => subOption.selected)
    );

    const activeSelections = [containersSelected, polygonsSelected, feederPolygonsSelected].filter(isSelected => isSelected).length;


    if (activeSelections > 1) {
      this.loading = false;
      this.showLayersPopupAlert = true;
      return;
    }


    this.totalPolygonsFromRes = [];
    this.drawLinesAndPoints([]);

    if (containersSelected) {     //Draw Containers selected
      for (const item of this.ds_layers) {
        for (const subItem of item.subItems) {
          if (subItem.selected) {
            await this.getLayer(subItem.name);
          } else {
            await this.removePoints(subItem.name);
            await this.removeLineStrings(subItem.name);
          }
        }
      }
    } else if (polygonsSelected) {       //Draw polygons selected
      for (const option of this.entityProvinceOption) {
        for (const subOption of option.subOptions) {
          if (subOption.selected) {
            const res = await firstValueFrom(this.polygonService.getByIDNomenclature(subOption.countryAndProvince));
            this.totalPolygonsFromRes = this.totalPolygonsFromRes.concat(res);
          }
        }
      }
    } else if (feederPolygonsSelected) {   //Draw feeder polygons selected
      for (const option of this.entityFeederPolygons) {
        for (const subOption of option.subOptions) {
          if (subOption.selected) {
            const res = await firstValueFrom(this.polygonService.getByStatus(subOption.status));
            this.totalPolygonsFromRes = this.totalPolygonsFromRes.concat(res);
          }
        }
      }
    }


    //Draw polygons selected

    if (polygonsSelected || feederPolygonsSelected) {
      await this.drawPolygon();
    }

    this.loading = false;
  }

  downloadMap(): void {
    const size = this.map.getSize();
    const pixelRatio = window.devicePixelRatio || 1;
    const mapCanvas = this.createMapCanvas(size, pixelRatio);
  
    const mapContext = mapCanvas.getContext('2d');
    if (!mapContext) {
      console.error('Could not get the canvas context.');
      return;
    }
  
    mapContext.scale(pixelRatio, pixelRatio);
  
    this.renderMapLayersToCanvas(mapContext, mapCanvas);
  
    this.map.once('rendercomplete', () => {
      this.downloadCanvasAsImage(mapCanvas);
    });
  
    this.map.renderSync();
  }
  
  createMapCanvas(size: [number, number], pixelRatio: number): HTMLCanvasElement {
    const mapCanvas = document.createElement('canvas');
    mapCanvas.width = size[0] * pixelRatio;
    mapCanvas.height = size[1] * pixelRatio;
    mapCanvas.style.width = `${size[0]}px`;
    mapCanvas.style.height = `${size[1]}px`;
    return mapCanvas;
  }
  
  renderMapLayersToCanvas(mapContext: CanvasRenderingContext2D, mapCanvas: HTMLCanvasElement): void {
    const mapLayers = document.querySelectorAll('.ol-layer canvas');
    mapLayers.forEach((canvas) => {
      if (canvas instanceof HTMLCanvasElement && canvas.width > 0) {
        const transform = canvas.style.transform;
        const matrix = transform
          .match(/^matrix\(([^\)]+)\)$/)?.[1]
          .split(',')
          .map(Number);
  
        if (matrix) {
          mapContext.setTransform(matrix[0], matrix[1], matrix[2], matrix[3], matrix[4], matrix[5]);
        } else {
          mapContext.setTransform(1, 0, 0, 1, 0, 0);
        }
  
        mapContext.drawImage(canvas, 0, 0);
      }
    });
  }
  
  downloadCanvasAsImage(mapCanvas: HTMLCanvasElement): void {
    mapCanvas.toBlob((blob) => {
      if (blob) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'map.png';
        link.click();
        URL.revokeObjectURL(link.href);
      }
    }, 'image/png');
  }
  

  searchByArea() {
    this.searchAreaInProgress = !this.searchAreaInProgress;

    this.selectedSearchAreaOption = this.polenoFilterOptions[this.searchAreaService.searchArea].name;
    this.searchAreaClicked = !this.searchAreaClicked;

    if (this.searchAreaInProgress) {
      let h: any = document.getElementsByClassName('ol-zoom')[0];
      h.style.display = 'none';
    } else {
      let h: any = document.getElementsByClassName('ol-zoom')[0];
      h.style.display = 'inline';
    }
  }

  searchByPolygon() {
    this.handleCancelSearch();
    this.selectedPolygonDrawOption = this.polenoFilterOptions[this.polygonDrawService.polygonDraw].name;
    if (this.sharedView == false) {
      this.polygonDrawClicked = !this.polygonDrawClicked;
      this.searchPolygonInProgress = !this.searchPolygonInProgress;
    } else {
      this.searchSharedViewInProgress = !this.searchSharedViewInProgress;
    }
    this.searchAreaInProgress = false;
  }

  clearSearchesPerformed() {
    this.selectedEntity = undefined;
    this.servicesSearchByUser = [];
    this.points = [];
    this.lineString = [];
    this.totalPolygonsFromRes = [];
    this.olMap.renderPolygons();
    this.mainService.triggerClearSearchesPerformed()
  }

  async search(filter: SearchPanelModel) {
    this.searchingFromSearchPanel = true;
    this.loading = true;
    let service: EntityService = this.datasources.getServiceByEntityName(filter.controller);

    try {
      // Handle proximity search
      if (filter.searchType === this.trans.instant('forms.map-viewer.search-by-proximity')) {
        try {
          this.points = []
          this.lineString = []
          this.servicesSearchByUser = [];
          this.searchPanelComponent.callGoogleMapFunction();
          this.goToLastPointPosition();
        } catch (error) {
          console.error('Error handling proximity search:', error);
        }
      } else {

        if (typeof filter.controller !== 'string') {
          filter.controller = (filter.controller as any)?.name;
        }


        if (filter.controller.toUpperCase() === Cons._SERVICE) {
          try {
            await this.handleServiceSearch(service, filter, true);
          } catch (error) {
            console.error('Error handling service search:', error);
          }
        } else if (filter.controller.toUpperCase() === Cons._TRACE) {
          try {
            await this.handleTraceSearch(service, filter);
          } catch (error) {
            console.error('Error handling trace search:', error);
          }
        } else {
          try {
            await this.handleSingleSearch(service, filter, false);
          } catch (error) {
            console.error('Error handling single search:', error);
          }
        }
      }
    } finally {
      if (filter.searchType !== this.trans.instant('forms.map-viewer.search-by-proximity'))
        this.loading = false;
    }
  }
  // indexService will always be 0 if the service is searched individually
  private async handleServiceSearch(service: EntityService, filter: SearchPanelModel, isVisible: boolean, showPosition: boolean = true, indexService: number = 0, searchByProximity: boolean = false) {
    this.loading = true;
    const response = await this.searchServiceElements(service, filter);
    const responseCopy = cloneDeep(response);

    this.selectedEntity = responseCopy;
    const clients = this.countClient(responseCopy.serviceAttributes.infrastructure);
    responseCopy.clients = clients;

    //check if the services are added to avoid duplicated data in proximityDetail Data Grid
    const availableServiceAlreadyExists = this.dataProximityDetail.dataAvailableServices.some(
      (x) => (x as ServiceResponse).name === responseCopy.name
    );
    const notAvailableServiceAlreadyExists = this.dataProximityDetail.dataServicesNotAvailable.some(
      (x) => (x as ServiceResponse).name === responseCopy.name
    );

    //this prevents adding ring services without headend in the map  when searching by proximity 
    const lastInfrastructureIndex = responseCopy.serviceAttributes.infrastructure.length - 1;
    const containsHeadend = responseCopy.serviceAttributes.infrastructure[lastInfrastructureIndex]?.elementType?.filePath === 'headend.png';
    if (!(searchByProximity && !containsHeadend)) {

      if (clients > 9 && searchByProximity == true) {
        if (responseCopy.name && !notAvailableServiceAlreadyExists) {
          this.dataProximityDetail.dataServicesNotAvailable.push(responseCopy);
        }
      } else {
        if (response.name && !availableServiceAlreadyExists) {
          this.dataProximityDetail.dataAvailableServices.push(responseCopy);
        }

        //this prevents drawing 100 rings in the map  when searching by proximity 
        if (indexService < 8) {


          const { drawnPoints, drawnLineStrings } = this.drawServiceElements(responseCopy);
          if (showPosition)
            this.goToLastPointPosition();

          let coord = this.coordinatesMap;
          this.selectedEntity.serviceAttributes.infrastructure = responseCopy.serviceAttributes.infrastructure.filter(f => f.locationData.coordinates.values.includes(`${coord[0]}`));


          const userSearch = this.addUserSearch(responseCopy, drawnPoints, drawnLineStrings);

          if (!isVisible)
            this.toggleServiceVisibility(userSearch);
        }

      }
    }
  }

  private async handleTraceSearch(service: EntityService, filter: SearchPanelModel) {
    this.isContainerDuctSearch = false;

    const responses = await this.searchSingleElement(service, filter);

    if (this.searchOneTrace) {
      await this.handleSingleSearch(service, filter, false);
      if (responses.sectionData) {
        this.getContainer_DuctIDfromResponse(responses.sectionData);
      }
    } else {
      responses.forEach(response => {
        this.drawSingleElement(response);

        if (response.sectionData) {
          this.getContainer_DuctIDfromResponse(response.sectionData);
        }
      });

      this.selectedEntity = responses[0];
      this.isSearchOpen = true;
    }
  }




  //Draw ducts related to a trace
  async handleContainer_DuctSearch(filter) {
    this.containerSearch = true;
    let service: EntityService = this.datasources.getServiceByEntityName('Container');

    await this.handleSingleSearch(service, filter, true);


  }


  private async handleSingleSearch(service: EntityService, filter: SearchPanelModel, isContainerDuctSearch: boolean) {
    this.isContainerDuctSearch = isContainerDuctSearch;
    const response = await this.searchSingleElement(service, filter);

    if (filter.controller == 'Polygon') {
      this.totalPolygonsFromRes = this.totalPolygonsFromRes.concat(response);
      this.drawPolygon();
      this.goToLastPolygonCoordinate(response);
    } else {
      this.drawSingleElement(response);
      if (!isContainerDuctSearch) {
        this.selectedEntity = response;
      }
    }
    this.isSearchOpen = true;
    this.loading = false;
  }

  async searchServiceElements(service: EntityService, filter: SearchPanelModel) {
    return await firstValueFrom(service.getByID(filter.id));
  }

  drawServiceElements(res): { drawnPoints: olMapPoint[], drawnLineStrings: olMapLineString[] } {
    const { container, device, infrastructure, trace } = res.serviceAttributes;
    const elements: ICommonEntity[][] = [container, device, infrastructure, trace];

    const { olDrawedLineStrings, olDrawedPoints } = this.drawLinesAndPoints(elements);

    const drawnPoints = olDrawedPoints.drawnPoints;
    const drawnLineStrings = olDrawedLineStrings.drawnLineStrings;

    return { drawnPoints, drawnLineStrings };
  }

  addUserSearch(response: any, drawnPoints: olMapPoint[], drawnLineStrings: olMapLineString[]): UserSearch {
    const serviceName: string = response?.name;
    const alreadyExists = this.servicesSearchByUser.some(x => x.name === serviceName);
    const existingUserSearch = this.servicesSearchByUser.find(x => x.name === serviceName);

    if (alreadyExists) {
      existingUserSearch.visible = false;
      this.toggleServiceVisibility(existingUserSearch);
      return null;
    }


    const userSearch: UserSearch = {
      name: serviceName,
      visible: true,
      mapFeatures: {
        lineStrings: drawnLineStrings,
        points: drawnPoints,
      },
      response: response
    };

    this.servicesSearchByUser.push(userSearch);
    this.selectedService = userSearch;

    return userSearch;
  }

  async searchSingleElement(service: EntityService, filter: SearchPanelModel) {
    if (filter.id && filter.controller.toUpperCase() != Cons._TRACE) {
      return await firstValueFrom(service.getByID(filter.id));
    } else {

      let entity: any = { platformID: this.platformService.platformID, name: filter.name };

      const response = await firstValueFrom(service.getWithFilter(entity, undefined, undefined, "OR"));

      if (Array.isArray(response) && response.length === 1) {
        this.searchOneTrace = true;
        return await firstValueFrom(service.getByID(filter.id));
      } else {
        this.searchOneTrace = false;
        return response;
      }
    }
  }
  drawSingleElement(element: ICommonEntity, isContainerDuctSearchRow: boolean = false) {
    const { olDrawedLineStrings, olDrawedPoints } = this.drawLinesAndPoints([[element]]);

    if (this.isContainerDuctSearch == false || isContainerDuctSearchRow == true) {
      const centerToElement = olDrawedLineStrings.drawnLineStrings.length === 1
        ? 'lastLineString'
        : (olDrawedPoints.drawnPoints.length === 1
          ? 'lastPoint'
          : undefined);
      this.centerMap(centerToElement);
    }
  }

  centerMap(centerTo: 'lastPoint' | 'lastLineString' | undefined) {
    if (centerTo === 'lastPoint') {
      this.goToLastPointPosition();
      return;
    }

    if (centerTo === 'lastLineString') {
      this.centerLineStringInMap();
      return;
    }
  }

  centerLineStringInMap() {
    const lastLineString = this.lineString[this.lineString.length - 1];
    let coordinates = Utilities.plainArray(lastLineString.flatCoordinates);
    this.olMap.moveTo(coordinates[1], coordinates[0], 18, 2500);
  }

  handleQuery(areaExtent, searchByArea: boolean = false, searchByPolygon: boolean = false) {
    this.loading = true;
    this.queryView(areaExtent, searchByArea, searchByPolygon);

    this.handleCancelSearch();
  }

  handleCancelSearch() {
    this.searchAreaInProgress = false;
    this.searchPolygonInProgress = false;
    this.searchSharedViewInProgress = false;
    this.dataMapDrawing = [];
    this.olMap.deleteDraw();

    let h: any = document.getElementsByClassName('ol-zoom')[0];
    h.style.display = 'inline';
  }

  handleCancelsharedView() {
    this.sharedViewShearchInProgress = false;
  }


  async queryView(extent, searchByArea: boolean = false, searchByPolygon: boolean = false) {
    let filter;
    let searchBarFilter;
    let searchareaFilter;
    let polygonDrawFilter;

    //----------------------------------------------------FILTER BY NETWORKTYPE---------------------------------------------// 
    if (this.searchingFromSearchPanel == false) {
      if (searchByArea == true) {
        searchareaFilter = this.selectedSearchAreaOption === this.polenoFilterOptions[0].name ? false : this.searchAreaCorpSelected || this.searchAreaFtthSelected;
      } else if (searchByPolygon == true) {
        if (this.selectedPolygonDrawOption === this.polenoFilterOptions[0].name) {
          polygonDrawFilter = false;
        } else {
          polygonDrawFilter = this.polygonDrawCorpSelected || this.polygonDrawFtthSelected;
        }
      }
    }
    else if (this.searchingFromSearchPanel == true) {
      searchBarFilter = extent.searchModel?.data?.selectedNetworkType;
      if (searchBarFilter != 'FTTH' && searchBarFilter != 'CORP') {
        searchBarFilter = undefined; //USE UNDEFINED TO SEARCH ALL ELEMENTS
      }
    }

    if (searchBarFilter) {
      filter = extent.searchModel.data.selectedNetworkType;
    }

    if (searchareaFilter) {
      filter = this.selectedSearchAreaOption;
    }
    if (polygonDrawFilter) {
      filter = this.selectedPolygonDrawOption;
    }
    //----------------------------------------------------END OF FILTER BY NETWORK TYPE---------------------------------------------// 


    const body = { platformID: this.platformService.platformID };
    let serviceObservables: Observable<any>[] = [];
    if (extent.searchModel != undefined) {
      this.searchByAreaLocal = false;

      if (this.searchPanelComponent.proximitySelected && this.searchPanelComponent.selectedServiceOrElement == 'Servicio') {
        serviceObservables.push(this.traceService.queryView(body, extent.areaExtent, 'INCLUDES', true));
      } else {
        switch (extent.searchModel.controller) {
          case 'Container':
            serviceObservables.push(await this.containerService.queryView(body, extent.areaExtent, 'CONTAINS'));
            break;
          case 'Device':
            serviceObservables.push(await this.deviceService.queryView(body, extent.areaExtent, 'CONTAINS', filter));
            break
          case 'Infrastructure':
            serviceObservables.push(await this.infraService.queryView(body, extent.areaExtent, 'CONTAINS', filter));
            break
          case 'Trace':
            serviceObservables.push(await this.traceService.queryView(body, extent.areaExtent, 'CONTAINS', false, filter, 20000));
            break
          default:
            break;
        }
      }
    } else {
      this.clearSearchesPerformed();
      this.searchByAreaLocal = true;
      serviceObservables = [
        await this.containerService.queryView(body, extent, 'CONTAINS'),
        await this.deviceService.queryView(body, extent, 'CONTAINS', filter),
        await this.infraService.queryView(body, extent, 'CONTAINS', filter),
        await this.traceService.queryView(body, extent, 'CONTAINS', false, filter, 50000),
      ];
    }

    if (this.searchByAreaLocal == true) {

      forkJoin(serviceObservables).subscribe({
        next: (res) => {
          this.loading = false;
          if (this.sharedViewShearchInProgress == true) {
            this.drawResponseForSharedView(body, extent, res);
          } else {
            this.drawLinesAndPoints(res);
          }
        },
        error: (err) => {
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        }
      });

    } else { //If you search by proximity.
      this.loading = true;
      forkJoin(serviceObservables).subscribe({
        next: async (res) => {

          this.dataProximityDetail.dataAvailableServices = [{}];
          this.dataProximityDetail.dataServicesNotAvailable = [{}];

          const shouldFilter = res.some(innerArray =>
            innerArray.some(element => element.entityName === 'INFRASTRUCTURE')
          );


          let filteredRes = shouldFilter
            ? res.map(innerArray => innerArray.filter(element => element.elementType.filePath == 'headend.png'))
            : res;

          if (this.searchPanelComponent.selectedServiceOrElement == 'Servicio' && filteredRes.length > 0) {
            let service: EntityService = this.datasources.getServiceByEntityName('Service');

            const orderedServices = this.filterAndOrderServices(filteredRes[0]);

            const searchPromises = orderedServices.map(async (element, index) => {
              if (index > 299) {
                return;
              }

              const filter = { controller: 'Service', id: element.serviceID, name: '', searchType: 'Servicio' };
              return this.handleServiceSearch(service, filter, true, false, index, true);
            });

            await Promise.all(searchPromises);

            this.updateProximityDataAvailableServices(this.dataProximityDetail.dataAvailableServices);
            this.updateProximityDataServicesNotAvailable(this.dataProximityDetail.dataServicesNotAvailable);
          } else {

            if (
              extent.searchModel?.data?.selectedType.name == 'Infrastructure' &&
              extent.searchModel?.data?.serviceNameOptionSelected != undefined
            ) {
              // let serviceFilter = extent.searchModel?.data?.serviceNameOptionSelected;

              filteredRes = await this.filterInfrastructuresByServices(extent, filteredRes);

            }

            await this.updateProximityDetailInSearchPanel(filteredRes[0]);
            await this.drawLinesAndPoints(filteredRes);
          }
        },
        error: (err) => {
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        }
      });

    }
    this.searchingFromSearchPanel = false;
  }

  drawLinesAndPoints(res: ICommonEntity[][], hideAllPoints?: boolean): { olDrawedLineStrings: OLDrawedLineStrings, olDrawedPoints: OLDrawedPoints } {
    const getGeometryType = (entity: ICommonEntity) => entity.locationData?.coordinates?.geographyType.toUpperCase();
    const isLine = (entity: ICommonEntity) => [OpenLayerGeometryTypes.LINESTRING, OpenLayerGeometryTypes.MULTILINESTRING].some(x => x === getGeometryType(entity));
    const isPoint = (entity: ICommonEntity) => getGeometryType(entity) === OpenLayerGeometryTypes.POINT;

    const elements = res.flatMap(x => x);
    const lines = elements.filter(p => isLine(p));
    const points = elements.filter(p => isPoint(p));

    const olDrawedLineStrings: OLDrawedLineStrings = this.lineDataLoad([lines], false);
    const olDrawedPoints: OLDrawedPoints = this.pointDataLoad([points], hideAllPoints);

    this.olDrawedLineStrings = olDrawedLineStrings;
    this.olDrawedPoints = olDrawedPoints;

    return { olDrawedLineStrings, olDrawedPoints };
  }

  lineDataLoad(res: ICommonEntity[][], isDuctSelected: boolean): OLDrawedLineStrings {
    let lineDS: olMapLineString[] = [];

    res.forEach(x => {
      const arrayX = Array.isArray(x) ? x : [x];

      arrayX.forEach((t: ICommonEntity) => {
        if (!lineDS.some((t1) => t1.id === t.commonID)) {
          //27-03-23: This happens because old services do not have the elementType property populated right... New imported services do.
          // let c = t.modelType?.specAttributes?.color;

          let c = t.elementType?.specAttributes?.color;
          if (!c) {
            if (isDuctSelected == true) {
              c = t.elementType?.specAttributes.color;
            } else {
              c = Cons.OLSTYLES.$traceDefaultColor;
            }
          }
          lineDS.push(this.olMapHelperService.OlMapLineString.toMapFeature(t, t.elementTypeID.toString(), c));
        }
      });
    });

    this.lineString = [...this.lineString, ...lineDS];

    return {
      preDrawnLineStrings: this.lineString,
      drawnLineStrings: lineDS
    };
  }

  pointDataLoad(res: ICommonEntity[] | ICommonEntity[][], hideAllPoints?: boolean): OLDrawedPoints {
    let markerDS: olMapPoint[] = [];

    res.forEach((x) => {
      if (x instanceof Array) {
        x.forEach((c: ICommonEntity) => {
          if (!markerDS.some((m1) => m1.id == c.entityName + '_' + c.commonID)) {
            markerDS.push(this.olMapHelperService.OlMapPoint.toMapFeature(c, c.elementTypeID.toString(), "", "", "markers/" + (<any>c.elementType).filePath, undefined, c.elementType?.specAttributes?.scale));
          }
        });
      }
      else {
        if (!markerDS.some((m1) => m1.id == x.entityName + '_' + x.commonID)) {
          markerDS.push(this.olMapHelperService.OlMapPoint.toMapFeature(x, x.elementTypeID.toString(), "", "", "markers/" + (<any>x.elementType).filePath, undefined, x.elementType?.specAttributes?.scale));
        }
      }
    });

    if (hideAllPoints) {
      markerDS.forEach(element => element.visible = false)
    }

    this.points = [...this.points, ...markerDS];

    return {
      preDrawnPoints: this.points,
      drawnPoints: markerDS
    }
  }

  selectDataMapDrawing(coordinates) {
    this.dataMapDrawing = coordinates.map((i) => ({ x: i[0], y: i[1] }));
    this.searchPolygonInProgress = !this.searchPolygonInProgress;
    this.searchSharedViewInProgress = !this.searchSharedViewInProgress;
  }

  goToPointPosition(points: olMapPoint[] = this.points) {
    const coordinates = points.flatMap(x => x.flatCoordinates).pop()
    this.olMap.moveTo(coordinates[1], coordinates[0], 14, 2500);
  }

  goToLastPointPosition(location?: google.maps.LatLng) {
    if (location) {
      const coordinates = [location.lng(), location.lat()]; // Orden: [lng, lat]
      const locationData = new LocationData('WKT', `POINT(${coordinates[0]} ${coordinates[1]})`, 0); // Ajusta inputSrID según corresponda
      this.displayPointOnMap(locationData, '', coordinates);
    } else {
      let coordinates = Utilities.plainArray(this.points[this.points.length - 1].flatCoordinates);
      this.coordinatesMap = coordinates;
      this.olMap.moveTo(coordinates[1], coordinates[0], 18, 2500);
    }
  }

  goToLastPolygonCoordinate(polygon) {
    try {
      const geometry = this.readWktGeometry(polygon[0]?.geom_wkt);

      if (geometry && geometry.getType() === 'Polygon') {
        const formattedCoordinates = this.formatPolygonCoordinates(geometry.getCoordinates());
        const lastCoordinates = [formattedCoordinates[formattedCoordinates.length - 1]];

        const latAndLongCoordinates = lastCoordinates[0];
        const offset = 0.5;
        const adjustedLongitude = latAndLongCoordinates[0] + offset;

        this.olMap.moveTo(latAndLongCoordinates[1], adjustedLongitude, 10, 2500);
      }
    } catch (error) {
      console.error('Error processing the polygon geometry:', error);
    }
  }

  displayPointOnMap(locationData: LocationData, name: string, coordinates?: any) {
    if (!locationData) {
      return;
    }
    const pointHelper: olMapPoint = new olMapPoint();

    const commonEntity: ICommonEntity = {
      name: name,
      locationData: locationData,
      commonID: null,
      entityName: null,
      elementType: null,
      elementTypeID: null,
      modelType: null
    };
    const point = pointHelper.toMapFeature(commonEntity, null, null, null, 'markers/pin.png', false, 0.8);
    this.points = [point];

    if (point.coordinateArray != null && point.coordinateArray.length > 1) {
      coordinates = point.coordinateArray;
    }


    // No es necesario ajustar coordinates aquí
    if (coordinates) {
      this.olMap.moveTo(coordinates[1], coordinates[0], 18, 2500);
    }
  }

  async removeService(userSearch: UserSearch, servicesSearchByUser: any = []) {
    const serviceName = userSearch?.name;
    const { lineStrings, points } = userSearch.mapFeatures;

    this.servicesSearchByUser = this.servicesSearchByUser.filter(x => x.name !== serviceName);
    this.lineString = this.lineString.filter(x => !lineStrings.map(t => t.id).includes(x.id));
    this.points = this.points.filter(x => !points.map(p => p.id).includes(x.id));
    this.selectedService = undefined;

    await this.drawServicesSearchByUser(userSearch, servicesSearchByUser);

  }

  async toggleServiceVisibility(userSearch: UserSearch, servicesSearchByUser: any = []) {

    userSearch.visible = !userSearch.visible;

    const toggleVisibleState = (x, data) => {
      if (data.map(y => y.id).includes(x.id)) {
        x.visible = userSearch.visible;
      }

      return x;
    }

    this.points = this.points.map(x => toggleVisibleState(x, userSearch.mapFeatures.points));
    this.lineString = this.lineString.map(x => toggleVisibleState(x, userSearch.mapFeatures.lineStrings));

    if (userSearch.visible == true) {
      await this.drawServiceElements(userSearch.response);
    }
    this.selectedService = !!userSearch.visible ? userSearch : undefined;

    await this.drawServicesSearchByUser(userSearch, servicesSearchByUser);

  }

  async drawServicesSearchByUser(userSearch: UserSearch, servicesSearchByUser: any) {
    const filteredServices = servicesSearchByUser.filter(service => service !== userSearch);

    if (filteredServices.length > 0) {

      await Promise.all(filteredServices.map(async service => {
        if (service.visible) {
          const { drawnPoints, drawnLineStrings } = await this.drawServiceElements(service.response);
        }
      }));

    }

  }
  centerService(userSearch: UserSearch) {
    if (!userSearch.visible) {
      this.toggleServiceVisibility(userSearch);
    }

    this.goToPointPosition(userSearch.mapFeatures.points);
    this.selectedService = userSearch;
  }

  onConnectionSelected(connection: Connection) {
    const getTraceID = (traceID: number) => `TRACE_${traceID}`;

    const toggleConnections = (select: boolean) => {
      this.selectedConnections
        .map((connectionRel: ConnectionRel) => getTraceID(connectionRel.traceID))
        .map((traceID: string) => this.olMapHelperService.getMapFeatureByID(this.olMap, traceID))
        .forEach((feature: Feature) => this.olMapHelperService.setStyleForLineStringFeature(feature, select));
    }

    if (connection) {
      this.selectedConnections = [connection.elementID1_Rel, connection.elementID2_Rel];
      toggleConnections(true);
    } else {
      if (!this.selectedConnections.length) {
        return;
      }

      toggleConnections(false);
      this.selectedConnections = [];
    }
  }

  async selectCheckbox(event: any, layerName: string) {
    const selected: boolean = event.value;
    this.selectedMainCheckbox = selected ? layerName : null;

    if (selected) {
      this.getLayer(layerName);
    } else {
      this.removePoints(['LH', 'URB']);
      this.removeLineStrings(['LH', 'URB']);
    }
  }

  selectSubCheckbox(event: any, elementTypeName: string) {
    const selected: boolean = event.checked;
    this.drawLinesAndPoints([]);
    if (selected) {
      this.getLayer(elementTypeName);
    } else {
      this.removePoints(elementTypeName);
      this.removeLineStrings(elementTypeName);
    }
  }

  removePoints(elementTypesName: string | string[]) {
    const elementTypesNameArray = Array.isArray(elementTypesName) ? elementTypesName : [elementTypesName];
    this.points = this.points.filter(element => !elementTypesNameArray.includes(element.type));
  }

  removeLineStrings(elementTypesName: string | string[]) {
    const elementTypesNameArray = Array.isArray(elementTypesName) ? elementTypesName : [elementTypesName];
    this.lineString = this.lineString.filter(element => !elementTypesNameArray.includes(element.type));
  }

  handleTextBoxFocusIn(eventData: boolean): void {
    this.showOverflow = false;
  }

  // Método para manejar el evento de pérdida de foco del cuadro de texto en SearchPanelComponent
  handleTextBoxFocusOut(eventData: boolean): void {
    this.showOverflow = true;
  }

  onAreaExtentCalculated(areaExtent: {}): void {
    this.handleQuery(areaExtent);
  }

  countClient(infra: [{}]): number {
    const count = infra.filter((item: any) => item.elementType.name === 'Edificio_Lindo').length;
    return count;
  }

  updateProximityDetailInSearchPanel(newValue: [{}]) {
    this.searchPanelComponent.updateDataTable(newValue);
  }

  updateProximityDataAvailableServices(value: [{}]) {
    this.searchPanelComponent.updateDataAvailableServices(value);
  }

  updateProximityDataServicesNotAvailable(value: [{}]) {
    this.searchPanelComponent.updateDataServicesNotAvailable(value);
  }

  onSwitchValueChanged(newValue: boolean) {
    this.switchValue = newValue;
    if (!newValue) {
      this.resetAllLineStringColors(this.lineString);
    }
    this.selectedElementsMap = [];
  }

  removeDuplicates(data: any[], property: string): any[] {
    const seen = new Set<string>();

    return data.filter(d => {
      if (property == '') {
        if (seen.has(d)) {
          return false;
        } else {
          seen.add(d);
          return true;
        }
      } else {
        if (seen.has(d[property])) {
          return false;
        } else {
          seen.add(d[property]);
          return true;
        }
      }
    });
  }
  resetAllLineStringColors(lineStrings: olMapLineString[]) {
    if (lineStrings.length > 0) {
      lineStrings.forEach(element => {
        element.color = "blue";
      });
      this.lineString = [...lineStrings];
      this.olMap.renderLineString();
    }
  }

  getDevicesBetween(diagram: ITraceabilityDiagram, elementID1: number, elementID2: number): any[] {
    const elements: { elementID: number, name: string, type: string }[] = [];

    for (const nodeArray of diagram.traceability) {
      // Encontrar los índices de los connectionID
      const index1 = nodeArray.findIndex(node => node.elementID === elementID1);
      const index2 = nodeArray.findIndex(node => node.elementID === elementID2);

      // Si ambos índices son válidos, se procesan
      if (index1 !== -1 && index2 !== -1) {
        // Ordenar los índices para garantizar un rango ascendente
        const [startIndex, endIndex] = [index1, index2].sort((a, b) => a - b);

        // Extraer los elementID en el rango
        for (let i = startIndex; i <= endIndex; i++) {
          if (nodeArray[i].elementID !== undefined && nodeArray[i].name !== undefined) {
            elements.push({ elementID: nodeArray[i].elementID!, name: nodeArray[i].name!, type: nodeArray[i].type! });
          }
        }
      }
    }

    return elements;
  }

  validateDataTraceabilityDiagram(data: ITraceabilityDiagram) {
    if (this.selectedElementsMap.length >= 2) {
      this.loading = true;
      let firstValue = this.selectedElementsMap[this.selectedElementsMap.length - 2].deviceID ?? this.selectedElementsMap[this.selectedElementsMap.length - 2].infrastructureID;
      let secondValue = this.selectedElementsMap[this.selectedElementsMap.length - 1].deviceID ?? this.selectedElementsMap[this.selectedElementsMap.length - 1].infrastructureID;
      let result = this.getDevicesBetween(data, firstValue, secondValue);

      result = this.removeDuplicates(result, 'elementID');
      let deviceObservables: Observable<any>[] = [];
      const elementIDs = result.map(element => element.elementID);
      if (elementIDs.length > 0) {
        deviceObservables.push(this.traceService.getTracesByDeviceIds(elementIDs, ''));
        forkJoin(deviceObservables).subscribe({
          next: (res) => {
            let _lineString: olMapLineString[] = [];
            this.lineString.forEach(element => {
              element.color = "blue";
              _lineString.push(element);
            });
            this.lineString = [];
            let total = 0;
            res[0].forEach(e => {
              let lineIndex = _lineString.findIndex(f => f.id == `TRACE_${e.traceID}`);
              if (lineIndex !== -1) {
                _lineString[lineIndex].color = "orange";
              }
              total += parseFloat(e.length);
            });

            this.lineString = _lineString;

            this.textBoxValue = `${total.toString()} mts`;
            let data: ITraceabilityDiagram = { serviceName: '', traceability: [] };
            let tracePrev = [];
            result.forEach((element, index) => {
              let traceability: SingleNode = {
                connectionID: index + 1,
                description: (res[0][index]?.name != undefined) ? `${res[0][index]?.name} (${res[0][index]?.length} mts)` : '',
                name: element.name,
                type: element.type
              }
              tracePrev.push(traceability);
            });
            data.traceability.push(tracePrev);
            this.dataToPass = data;
            this.loading = false;
          },
          error: (err) => {
            this.loading = false;
          }
        });
      } else this.loading = false;
    } else
      this.loading = false;
  }

  //Find ducts related to a trace
  getContainer_DuctIDfromResponse(sectionData: string) {
    try {
      const parsedData = JSON.parse(sectionData);

      if (parsedData && parsedData.containerid) {
        let containerID = parsedData.containerid
          .replace(/[\[\]]/g, '')
          .split(',')
          .map(Number);

        if (containerID.length > 0 && containerID.some(id => !isNaN(id))) {
          this.getContainer_DuctfromContainerID(containerID);
        }
      }
    } catch (error) {
      console.error('Error parsing sectionData:', error);
    }
  }

  getContainer_DuctfromContainerID(containerID: number[]) {
    containerID.forEach(id => {
      this.containerService.getByID(id).subscribe(
        (response) => {
          const filter = {
            controller: 'Container',
            id: response.containerID,
            name: response.name || '',
            searchType: 'Elemento'
          };

          this.handleContainer_DuctSearch(filter);
        },
        (error) => {
          console.error('Error retrieving container data:', error);
        }
      );
    });
  }

  async handleSelectedService(event: any) {

    if (event.name != undefined) {
      if (this.servicesSearchByUser.length > 8) {

        this.showMaxServiceReachedModal = true;
        return null;
      }

      let service: EntityService = this.datasources.getServiceByEntityName('Service');
      const filter = { controller: 'Service', id: event.serviceID, name: '', searchType: 'Servicio' };
      await this.handleServiceSearch(service, filter, true);
    }
    this.loading = false;
  }

  drawResponseForSharedView(body, extent, res) {
    this.sharedViewShearchInProgress = false;

    // Get selected entities
    const selectedEntity = this.getSelectedEntities();

    // Group results based on selected entities
    const groupedRes = this.filterByEntity(selectedEntity, res);

    // Get selected suboptions
    const selectedSubOptions = this.getSelectedSubOptions();

    // Combine selected entities and suboptions
    const selectedEntityAndSubOption = this.getSelectedEntityAndSubOptions(selectedEntity, selectedSubOptions);

    // Filter by subtype
    const filteredBySubtype = this.filterBySubtype(groupedRes, selectedSubOptions);

    // Perform drawing operations
    this.drawLinesAndPoints(filteredBySubtype);

    // Generate URL for selected entities and suboptions
    this.generateURL(body, extent, selectedEntityAndSubOption);
  }

  getSelectedEntities() {
    return this.entityOption
      .filter(option => option.selected)
      .map(option => option.name.toUpperCase());
  }

  filterByEntity(selectedEntity, res) {
    return res.flatMap(entityArray =>
      entityArray.filter(item => selectedEntity.includes(item.entityName.toUpperCase()))
    );
  }

  getSelectedSubOptions() {
    return this.entityOption.flatMap(option =>
      option.subOptions
        .filter(sub => sub.selected)
        .map(sub => ({
          id: sub.id.toUpperCase(),
          filePath: sub.filePath,
          name: sub.name.toUpperCase()
        }))
    );
  }

  getSelectedEntityAndSubOptions(selectedEntity, selectedSubOptions) {
    return [
      ...selectedEntity,
      ...selectedSubOptions.map(sub => sub.id)
    ];
  }

  filterBySubtype(groupedRes, selectedSubOptions) {
    return groupedRes.map(item => {
      if (item.entityName.toUpperCase() === 'TRACE') {
        return item;
      }

      const subOption = selectedSubOptions.find(sub => {
        if (sub.id === 'CONTAINER_DUCTO_LH') {
          return item.elementType.name.toUpperCase() === 'LH';
        } else if (sub.id === 'CONTAINER_DUCTO_URB') {
          return item.elementType.name.toUpperCase() === 'URB';
        }
        return item.elementType.filePath === sub.filePath;
      });

      return subOption ? item : null;
    }).filter(Boolean);
  }


  async generateURL(body, extent, selectedEntityAndSubOption) {
    const payload = {
      body,
      selectedEntityAndSubOption,
      iat: Math.floor(Date.now() / 1000), // Issued At Time
      exp: Math.floor(Date.now() / 1000) + 3600 // Expiration Time (1 hour)
    };

    const secret = "ijurkbdlhmklqacwqzdxmkkhvqowlyqa";

    // Header for the JWT
    const header = { alg: "HS256", typ: "JWT" };

    // Sign the JWT with the header, payload, and secret
    const token = KJUR.jws.JWS.sign("HS256", JSON.stringify(header), JSON.stringify(payload), secret);

    const url = `${apiSelector.sharedView.url}${token}`;

    this.generatedURL = url;
    this.urlPopupVisible = true;
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.generatedURL).then(() => {
      this.urlPopupVisible = false;
    }).catch(err => {
      console.error('Error copying URL: ', err);
    });
  }

  updateParentCheckbox(option: any) {
    const checkedCount = option.subOptions.filter((sub: any) => sub.selected).length;

    if (checkedCount > 0) {
      option.selected = true;
      option.indeterminate = checkedCount < option.subOptions.length;
    } else {
      option.selected = false;
      option.indeterminate = false;
    }
  }

  toggleAllSubOptions(option: any) {
    const isChecked = option.selected;

    option.subOptions.forEach((sub: any) => {
      sub.selected = isChecked;
    });
  }

  serviceFilter(event: any) {

    this.serviceFilterData = event;
    this.serviceFilterData = this.serviceFilterData.toUpperCase();
  }

  filterAndOrderServices(elements: any[]): any[] {
    return elements
      .filter((element) => {
        return this.serviceFilterData ? element.serviceName.includes(this.serviceFilterData) : true;
      })
      .sort((a, b) => a.index - b.index);
  }

  async filterInfrastructuresByServices(extent: any, filteredRes: any) {
    let serviceFilter = extent.searchModel?.data?.serviceNameOptionSelected;

    try {
      const infrastructuresWithAssociatedServices = await this.updateInfrastructureWithServices(filteredRes);

      filteredRes = await this.filterInfrastructuresByServiceName(infrastructuresWithAssociatedServices, serviceFilter);

    } catch (error) {
      console.error('Error getting infrastructures with services:', error);
    }
    return [filteredRes];

  }

  async updateInfrastructureWithServices(filteredRes: any[][]): Promise<any[][]> {
    const odfData: any[] = [];

    try {
      const odfPromises = filteredRes[0].map(infrastructure =>
        this.infraService.getODF(infrastructure.infrastructureID).toPromise().then(response => ({
          infrastructureID: infrastructure.infrastructureID,
          odf: response || []
        }))
      );

      const results = await Promise.all(odfPromises);

      results.forEach(result => odfData.push(result));

      for (const infrastructure of filteredRes[0]) {
        const matchedODFData = odfData.find(
          (odf) => odf.infrastructureID === infrastructure.infrastructureID
        );
        if (matchedODFData) {
          infrastructure.odf = matchedODFData.odf;
        }
      }

    } catch (error) {
      console.error('Error fetching ODF data:', error);
      throw error;
    }

    return filteredRes;
  }


  async filterInfrastructuresByServiceName(
    filteredRes: any[][],
    serviceFilter: string
  ): Promise<any[]> {
    try {
      const filteredInfrastructures = filteredRes[0].filter(infrastructure => {
        if (!infrastructure.odf || infrastructure.odf.length === 0) return false;
  
        return infrastructure.odf.some(odf =>
          Array.isArray(odf.children) && odf.children.some(child =>
            child.descr && child.descr.includes(serviceFilter)
          )
        );
      });
  
      return filteredInfrastructures;
    } catch (error) {
      console.error('Error filtering infrastructures:', error);
      throw error;
    }
  }





  async searchElementsInsidePolygon(event: any) {

    this.entityOption = event.selectedElements;
    this.loading = true;
    let coordinatesEPSG4326;
    let filter;
    const body = { platformID: this.platformService.platformID };
    let serviceObservables: Observable<any>[] = [];

    const geometry = this.readWktGeometry(event.selectedPolygonData[0].geom_wkt);
    const formattedCoordinates = this.formatPolygonCoordinates(geometry.getCoordinates());

    // Entire network selected
    if (event.selectedNetWorkType == this.polenoFilterOptions[0].name) {
      filter = undefined;
      this.polygonDrawCompleteSelected = true;
      this.polygonDrawCorpSelected = false;
      this.polygonDrawFtthSelected = false;

      // Corporate network selected
    } else if (event.selectedNetWorkType == this.polenoFilterOptions[1].name) {
      filter = this.polenoFilterOptions[1].name;
      this.polygonDrawCompleteSelected = false;
      this.polygonDrawCorpSelected = true;
      this.polygonDrawFtthSelected = false;

      // FTTH network selected
    } else if (event.selectedNetWorkType == this.polenoFilterOptions[2].name) {
      filter = this.polenoFilterOptions[2].name;
      this.polygonDrawCompleteSelected = false;
      this.polygonDrawCorpSelected = false;
      this.polygonDrawFtthSelected = true;
    }

    // Convert coordinates [lat, lon] to { x: lon, y: lat }
    if (formattedCoordinates && Array.isArray(formattedCoordinates)) {
      coordinatesEPSG4326 = formattedCoordinates.map((coord: [number, number]) => ({
        x: coord[0], // Longitude (lon)
        y: coord[1], // Latitude (lat)
      }));
    }

    if (event.selectedElements && event.selectedElements.length > 0) {
      const selectedElements = event.selectedElements.filter(element => element.selected);
      if (selectedElements.length > 0) {
        const selectedNames = selectedElements.map(element => element.name);

        if (selectedNames.includes("Container")) {
          serviceObservables.push(await this.containerService.queryView(body, coordinatesEPSG4326, 'CONTAINS'));
        }
        if (selectedNames.includes("Device")) {
          serviceObservables.push(await this.deviceService.queryView(body, coordinatesEPSG4326, 'CONTAINS', filter));
        }
        if (selectedNames.includes("Infrastructure")) {
          serviceObservables.push(await this.infraService.queryView(body, coordinatesEPSG4326, 'CONTAINS', filter));
        }
        if (selectedNames.includes("Trace")) {
          serviceObservables.push(await this.traceService.queryView(body, coordinatesEPSG4326, 'CONTAINS', false, filter, 20000));
        }
      } else {
        console.error("No elements with 'selected: true' have been selected.");
      }
    } else {
      console.error("No elements have been selected.");
    }

    forkJoin(serviceObservables).subscribe({
      next: async (res) => {
        this.loading = false;
        this.sharedViewShearchInProgress = false;

        const selectedEntity = await this.getSelectedEntities();

        const groupedRes = await this.filterByEntity(selectedEntity, res);

        const selectedSubOptions = await this.getSelectedSubOptions();

        const filteredBySubtype = await this.filterBySubtype(groupedRes, selectedSubOptions);

        this.drawLinesAndPoints(filteredBySubtype);
      },
      error: (err) => {
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  onMapReady(map: any) {
    this.map = map;
  }
}