import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, NgZone, Input, SimpleChanges } from '@angular/core';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss']
})
export class AddressAutocompleteComponent implements OnInit {
  @ViewChild('autocomplete') autocompleteInput!: ElementRef;
  @Input() address: string = '';
  @Input() addressOrCoordinates: string = '';
  @Output() placeSelected = new EventEmitter<google.maps.places.PlaceResult>();

  private placeResult: google.maps.places.PlaceResult | null = null;

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['address'] && this.autocompleteInput) {
      this.autocompleteInput.nativeElement.value = this.address;
    }
  }

  ngOnInit(): void {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.nativeElement, {
        types: ['address']
      });

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.placeResult = place;
          this.placeSelected.emit(place);
        });
      });
    });
  }

  getPlace(): google.maps.places.PlaceResult | string | undefined {
    if (this.addressOrCoordinates == 'address') {
      if (this.placeResult) {
        return this.placeResult;
      }
    } else if (this.addressOrCoordinates == 'coordinate') {
      const inputText = this.autocompleteInput.nativeElement.value;

      if (this.isCoordinatesFormat(inputText)) {
        const coordinates = this.parseCoordinates(inputText);
        if (coordinates) {
          return `searchByCoordinates: ${inputText}`;
        } else {
          console.error('Invalid coordinates format.');
          return undefined;
        }
      }
    }

    return undefined;
  }

  private isCoordinatesFormat(inputText: string): boolean {
    const coordinatesPattern = /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/;
    return coordinatesPattern.test(inputText);
  }

  private parseCoordinates(inputText: string): { lat: number, lng: number } | null {
    const coordinates = inputText.split(',').map(coord => parseFloat(coord.trim()));

    if (!isNaN(coordinates[0]) && !isNaN(coordinates[1])) {
      return { lat: coordinates[0], lng: coordinates[1] };
    }

    return undefined;
  }



}
