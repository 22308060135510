import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { MainService } from '../main.service';
import { Cons } from '@app/@shared/cons/cons';
import { Polygon } from '@app/@shared/model/interface/polygon-interface';

@Injectable({
  providedIn: 'root'
})
export class PolygonService {

  constructor(private http: HttpClient, private main: MainService) { }


  getByIDNomenclature(id: any): Observable<any> {
    this.main.chooseAPI(Cons._POLYGON);
    return this.main.getByID(`${Cons._POLYGONTYPE}/GetPolygonByCountryAndProvince`, id).pipe(
      map((res) => {
        try {
          return <any>res;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }


  getWithFilter(entity?: any, limit?: number, offset?: number): Observable<any> {
    this.main.chooseAPI(Cons._POLYGON);
    // let body = this.cleanUpForRequest(entity);
    let body;
    return this.main.getWithFilter(Cons._POLYGONTYPE, entity, true, limit, offset).pipe(
      map((res) => {
        try {
          return res.responseData.map((x) => {
            x.commonID = x.boundaryID;
            x.entityName = Cons._POLYGON;
            return <any>x;
          });
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  getByID(id: any): Observable<any> {
    this.main.chooseAPI(Cons._POLYGON);
    return this.main.getByID(`${Cons._POLYGON}/GetByID`, id).pipe(
      map((res) => {
        try {
          res.commonID = res[0].boundaryID;
          res.entityName = Cons._POLYGON;
          return <any>res;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  getByStatus(id: any): Observable<any> {
    this.main.chooseAPI(Cons._POLYGON);
    return this.main.getByID(`${Cons._POLYGONTYPE}/GetFeederPolygonByStatus`, id).pipe(
      map((res) => {
        try {
          return res.map((x) => {
            x.namE_COUNTY = x.nombre;
            x.ogc_geometry_wkt = x.geom;
            return <any>x;
          });
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }
}
