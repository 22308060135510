import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'polygon-filter',
  templateUrl: './polygon-filter.component.html',
  styleUrls: ['./polygon-filter.component.scss']
})
export class PolygonFilterComponent implements OnInit {
  @Output() selectedPolygonDataOutput = new EventEmitter<any>();
  _entity: any;

  @Input('entity')
  get entity() {
    return this._entity;
  }
  set entity(value) {
    if (value == this._entity) {
      return;
    }
    this._entity = value;
  }


  selectedElements: any[] = [];
  selectedNetWorkType: any;
  selectedPolygonData: any = null;
  selectedNetworkType: any;

  singleAccordionItem = {
    title: this.trans.instant('comp.polygon-filter.title'),
  };

  entityOption = [
    {
      id: 'container',
      name: 'Container',
      selected: true,
      subOptions: [
        { id: 'container_poste', name: 'Postes', filePath: 'container_poste.png', selected: true },
        { id: 'container_ducto_lh', name: 'Ductos LH', selected: true },
        { id: 'container_ducto_urb', name: 'Ductos URB', selected: true },
        { id: 'container_camara', name: 'Camaras', filePath: 'container_camara.png', selected: true },
      ],
    },
    {
      id: 'device',
      name: 'Device',
      selected: true,
      subOptions: [
        { id: 'device_empalme', name: 'Cajas de Empalme', filePath: 'device.png', selected: true },
        { id: 'device_cto', name: 'CTO', filePath: 'device_fibernode.png', selected: true },
      ],
    },
    {
      id: 'trace',
      name: 'Trace',
      selected: true,
      subOptions: [],
    },
    {
      id: 'infrastructure',
      name: 'Infrastructure',
      selected: true,
      subOptions: [
        { id: 'infrastructure_headend', name: 'Cabeceras', filePath: 'headend.png', selected: true },
        { id: 'infrastructure_cliente', name: 'Clientes (FO)', filePath: 'building.png', selected: true },
      ],
    },
  ];


  polenoFilterOptions = [
    { 
      name: this.trans.instant('general.all-network'), 
      value: this.trans.instant('general.all-network')
    }, 
    { 
      name: 'CORP', 
      value: 'CORP' 
    }, 
    { 
      name: 'FTTH', 
      value: 'FTTH' 
    }
  ];

  constructor(private trans: TranslateService) { }


  ngOnInit(): void {
  }

  updateParentCheckbox(option: any) {
    const checkedCount = option.subOptions.filter((sub: any) => sub.selected).length;

    if (checkedCount > 0) {
      option.selected = true;
      option.indeterminate = checkedCount < option.subOptions.length;
    } else {
      option.selected = false;
      option.indeterminate = false;
    }
  }

  toggleAllSubOptions(option: any) {
    const isChecked = option.selected;

    option.subOptions.forEach((sub: any) => {
      sub.selected = isChecked;
    });
  }


  onNetworkTypeChange(selectedNetwork: any) {
    this.selectedNetworkType = selectedNetwork;
  }


  onSearchButtonClick() {

    if (!this.selectedNetworkType) {
      notify(this.trans.instant('comp.polygon-filter.networktype-not-selected'), 'error', 2000);
      return;
    }

    this.selectedPolygonDataOutput.emit({
      selectedPolygonData: this._entity,
      selectedElements: this.entityOption,
      selectedNetWorkType: this.selectedNetworkType
    });

  }

}
