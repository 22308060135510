<h2 class="welcome-message">{{ welcomeMessage }}</h2>

<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center top" style="margin-top: 3em">
  <navigation-menu (emitShowNetWorkReport)="handleEmitShowNetWorkReport($event)"
    (showConstructiveFeasibilityOutput)="handleEmitShowConstructiveFeasibilityOutput($event)"
    (showAuditOutput)="handleShowAudit($event)"
    fxFlex="20"></navigation-menu>
    <div fxFlex="80">
      <ng-container *ngIf="showNetWorkReport; else constructiveFeasibilitySection">
        <main-screen [showNetWorkReport]="showNetWorkReport"></main-screen>
      </ng-container>
    
      <ng-template #constructiveFeasibilitySection>
        <ng-container *ngIf="showConstructiveFeasibility; else auditSection">
          <app-constructive-feasibility></app-constructive-feasibility>
        </ng-container>
    
        <ng-template #auditSection>
          <main-screen [showNetWorkReport]="false"></main-screen>
        </ng-template>
      </ng-template>
    </div>
</div>