import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { GeneralRoutingModule } from './general-routing.module';
import { HeaderComponent } from '../shell/header/header.component';

// Material Form Controls
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

// Material Popups & Modals
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// Material Data tables
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';

import {
  DxAccordionModule,
  DxAutocompleteModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDrawerModule,
  DxFileUploaderModule,
  DxFormModule,
  DxListModule,
  DxGalleryModule,
  DxLoadIndicatorModule,
  DxLoadPanelModule,
  DxNumberBoxModule,
  DxPieChartModule,
  DxPopoverModule,
  DxPopupModule,
  DxProgressBarModule,
  DxRadioGroupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxSpeedDialActionModule,
  DxSwitchModule,
  DxTabPanelModule,
  DxTabsModule,
  DxTagBoxModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxTooltipModule,
  DxValidatorModule,
  DxToastModule,
  DxTreeViewModule,
  DxColorBoxModule,
  DxContextMenuModule,
} from 'devextreme-angular';
import { AddressAutocompleteComponent } from '@app/@shared/components/search-panel/adress-autcomplete/address-autocomplete.component';
import { MapViewerComponent } from './map-viewer/map-viewer.component';
import { GoogleMapView } from '@app/@shared/components/search-panel/google-map-view/google-map-view.component';
import { OlMapComponent } from '@app/@shared/components/ol-map/ol-map.component';
import { SearchPanelComponent } from '@app/@shared/components/search-panel/search-panel.component';
import { ContainerDetailComponent } from '@app/@shared/components/search-panel/container-detail/container-detail.component';
import { DeviceDetailComponent } from '@app/@shared/components/search-panel/device-detail/device-detail.component';
import { ServiceDetailComponent } from '@app/@shared/components/search-panel/service-detail/service-detail.component';
import { InfrastructureDetailComponent } from '@app/@shared/components/search-panel/infrastructure-detail/infrastructure-detail.component';
import { TraceDetailComponent } from '@app/@shared/components/search-panel/trace-detail/trace-detail.component';
import { DxoAnimationModule } from 'devextreme-angular/ui/nested';
import { SplitterDetailComponent } from '@app/@shared/components/search-panel/splitter-detail/splitter-detail.component';
import { HomeSurveyComponent } from './survey/home/home-survey.component';
import { PlotFormComponent } from './survey/plot-form/plot-form.component';
import { EditAddressModalComponent } from './survey/modals/edit-address-modal/edit-address-modal.component';
import { PlotSavedSuccessfullyModalComponent } from './survey/modals/plot-saved-successfully-modal/plot-saved-successfully-modal.component';
import { FileUploadComponent } from '@app/@shared/components/file-uploader/file-uploader.component';
import { SharedModule } from '@app/@shared';
import { ThirdPartyModule } from '@app/@shared/module/third-party.module';
import { ServiceChipListComponent } from './map-viewer/service-chip-list/service-chip-list.component';
import { AddressFormComponent } from '@app/@shared/components/address-map/address-form.component';
import { ClientFeasibilityHistoryListComponent } from './feasibility/clients/feasibility-history-list/feasibility-history-list.component';
import { DeviceHomeComponent } from './device/home/device-home.component';
import { DeviceListComponent } from './device/list/device-list.component';
import { CreateEditDeviceModalComponent } from './device/modal/create-edit-device-modal.component';
import { DevicePortListComponent } from './device/modal/port-list/device-port-list.component';
import { DeviceSplitterListComponent } from './device/modal/splitter-list/device-splitter-list.component';
import { InfrastructureODFComponent } from '@app/@shared/components/search-panel/infrastructure-detail/infrastructure-odf/infrastructure-odf.component';
import { SplitterBoxComponent } from '@app/@shared/components/search-panel/splitter-detail/splitter-box/splitter-box.component';
import { DeviceBoxComponent } from '@app/@shared/components/search-panel/device-detail/device-box/device-box.component';
import { ElementTypeHomeComponent } from './element-type/home/element-type-home.component';
import { OdfFrontViewComponent } from '@app/@shared/components/search-panel/infrastructure-detail/infrastructure-odf/odf-front-view/odf-front-view.component';
import { OdfGridViewComponent } from '@app/@shared/components/search-panel/infrastructure-detail/infrastructure-odf/odf-grid-view/odf-grid-view.component';
import { PortDetailsModalComponent } from '@app/@shared/components/search-panel/infrastructure-detail/infrastructure-odf/port-details-modal/port-details-modal.component';
import { TraceabilityDiagramComponent } from '@app/@shared/components/search-panel/infrastructure-detail/infrastructure-odf/traceability-diagram/traceability-diagram.component';
import { FeasibilityAllocationComponent } from './feasibility/clients/feasibility-allocation/feasibility-allocation.component';
import { FeasibilityHomeComponent } from './feasibility/home/feasibility-home.component';
import { FeasibilitySingleComponent } from './feasibility/modal/feasibility-single-modal.component';
import { EquipmentTypeHomeComponent } from './equipment-type/home/equipment-type-home.component';
import { TraceHomeComponent } from './trace/home/trace-home.component';
import { TraceListComponent } from './trace/list/trace-list.component';
import { ContainerHomeComponent } from './container/home/container-home.component';
import { ContainerListComponent } from './container/list/container-list.component';
import { CreateEditContainerModalComponent } from './container/modal/create-edit-container-modal.component';
import { ProximityDetailComponent } from '@app/@shared/components/search-panel/proximity-detail/proximity-detail.component';
import { HeadendDetailComponent } from '@app/@shared/components/search-panel/headend-detail/headend-detail.component';

import { ElementTypeSingleComponent } from './element-type/single-modal/element-type-single.component';
import { SplitterTypeComponent } from './splitter-type/home/splitter-type.component';
import { SplitterTypeListComponent } from './splitter-type/splitter-type-list/splitter-type-list.component';
import { SplitterTypeModalComponent } from './splitter-type/splitter-type-modal/splitter-type-modal.component';
import { UsersHomeComponent } from './users/home/users-home.component';
import { EquipmentTypeListComponent } from './equipment-type/equipment-type-list/equipment-type-list.component';
import { EquipmentTypeModalComponent } from './equipment-type/equipment-type-modal/equipment-type-modal.component';
import { TraceModalComponent } from './trace/trace-modal/trace-modal.component';
import { TraceModalMapsComponent } from './trace/trace-modal/trace-modal-maps/trace-modal-maps.component';
import { TraceModalFiberListComponent } from './trace/trace-modal/trace-modal-fiber-list/trace-modal-fiber-list.component';
import { EquipmentListComponent } from './equipment/list/equipment-list.component';
import { CreateEditEquipmentModalComponent } from './equipment/modal/create-edit-equipment-modal.component';
import { EquipmentHomeComponent } from './equipment/home/equipment-home.component';
import { InfrastructureHomeComponent } from './infrastructure/home/infrastructure-home.component';
import { InfrastructureListComponent } from './infrastructure/list/infrastructure-list.component';
import { CreateEditInfrastructureModalComponent } from './infrastructure/modal/create-edit-infrastructure-modal.component';
import { CommonFilterModule } from '@app/@shared/components/common-filter/common-filter.module';
import { UsersSingleModalComponent } from './users/single-modal/users-single-modal.component';
import { ElementType } from '@app/@shared/model/elementType.model';
import { SpecTreeViewModule } from '@app/@shared/components/spec-tree-view/spec-tree-view.module';
import { QRCodeComponent } from './qr-code/qr-code/qr-code.component';
import { GalleryViewerComponent } from '@app/@shared/components/search-panel/gallery-viewer/gallery-viewer/gallery-viewer.component';
import { OdfCommentSectionComponent } from '@app/@shared/components/search-panel/infrastructure-detail/infrastructure-odf/odf-comment-section/odf-comment-section/odf-comment-section.component';
import { FiberDiagramComponent } from '@app/@shared/components/Gojs/fiber-diagram/fiber-diagram.component';
import { ProjectDetailComponent } from '@app/@shared/components/search-panel/project-detail/project-detail.component';
import { TraceDiagramComponent } from '@app/@shared/components/d3/horizontal-tree-container-device/trace-diagram/trace-diagram.component';
import { PolygonFilterComponent } from '@app/@shared/components/search-panel/polygon-filter/polygon-filter/polygon-filter.component';
import { ConstructiveFeasibilityMapComponent } from '@app/@shared/components/constructive-feasibility-map/constructive-feasibility-map.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    GeneralRoutingModule,
    NgbModule,
    FlexLayoutModule,
    SharedModule,
    ScrollingModule,
    TranslateModule,
    CommonFilterModule,
    SpecTreeViewModule,


    ThirdPartyModule,
    //DEV EXPRESS
    DxButtonModule,
    DxDataGridModule,
    DxTextBoxModule,
    DxNumberBoxModule,
    DxTextAreaModule,
    DxAutocompleteModule,
    DxSpeedDialActionModule,
    DxTabPanelModule,
    DxLoadPanelModule,
    DxValidatorModule,
    DxFormModule,
    DxSelectBoxModule,
    DxDrawerModule,
    DxAccordionModule,
    DxRadioGroupModule,
    DxPopoverModule,
    DxoAnimationModule,
    DxSwitchModule,
    DxCheckBoxModule,
    DxPopupModule,
    DxFileUploaderModule,
    DxProgressBarModule,
    DxTooltipModule,
    DxScrollViewModule,
    DxTagBoxModule,
    DxLoadIndicatorModule,
    DxTabsModule, DxPieChartModule,
    DxGalleryModule,
    DxListModule,
    DxToastModule
  ],
  declarations: [
    HeaderComponent,
    MapViewerComponent,
    OlMapComponent,
    SearchPanelComponent,
    ContainerDetailComponent,
    DeviceDetailComponent,
    ServiceDetailComponent,
    DeviceBoxComponent,
    InfrastructureDetailComponent,
    InfrastructureODFComponent,
    TraceabilityDiagramComponent,
    OdfFrontViewComponent,
    OdfGridViewComponent,
    PortDetailsModalComponent,
    TraceDetailComponent,
    SplitterDetailComponent,
    SplitterBoxComponent,
    HomeSurveyComponent,
    PlotFormComponent,
    EditAddressModalComponent,
    PlotSavedSuccessfullyModalComponent,
    FileUploadComponent,
    QRCodeComponent,
    GalleryViewerComponent,
    OdfCommentSectionComponent,
    FiberDiagramComponent,
    ProjectDetailComponent,
    TraceDiagramComponent,
    PolygonFilterComponent,
    ConstructiveFeasibilityMapComponent,
    ServiceChipListComponent,
    FeasibilityHomeComponent,
    FeasibilitySingleComponent,
    AddressFormComponent,
    ClientFeasibilityHistoryListComponent,
    DeviceHomeComponent,
    DeviceListComponent,
    CreateEditDeviceModalComponent,
    DevicePortListComponent,
    DeviceSplitterListComponent,
    ElementTypeHomeComponent,
    ElementTypeSingleComponent,
    SplitterTypeComponent,
    SplitterTypeListComponent,
    SplitterTypeModalComponent,
    EquipmentHomeComponent,
    EquipmentListComponent,
    CreateEditEquipmentModalComponent,
    FeasibilityAllocationComponent,
    EquipmentTypeHomeComponent,
    TraceHomeComponent,
    TraceListComponent,
    ContainerHomeComponent,
    ContainerListComponent,
    CreateEditContainerModalComponent,
    UsersHomeComponent,
    UsersSingleModalComponent,
    EquipmentTypeListComponent,
    EquipmentTypeModalComponent,
    TraceModalComponent,
    TraceModalMapsComponent,
    TraceModalFiberListComponent,
    InfrastructureHomeComponent,
    InfrastructureListComponent,
    CreateEditInfrastructureModalComponent,
    ProximityDetailComponent,
    HeadendDetailComponent,
    GoogleMapView,
    AddressAutocompleteComponent
  ],
  exports: [
    QRCodeComponent,
    GalleryViewerComponent,
    OdfCommentSectionComponent,
    FiberDiagramComponent,
    ProjectDetailComponent,
    TraceDiagramComponent,
    PolygonFilterComponent,
    ConstructiveFeasibilityMapComponent,
    OlMapComponent,
    SearchPanelComponent,
    ContainerDetailComponent,
    DeviceDetailComponent,
    DeviceBoxComponent,
    ServiceDetailComponent,
    InfrastructureDetailComponent,
    InfrastructureODFComponent,
    TraceabilityDiagramComponent,
    OdfFrontViewComponent,
    OdfGridViewComponent,
    PortDetailsModalComponent,
    TraceDetailComponent,
    SplitterDetailComponent,
    SplitterBoxComponent,
    HomeSurveyComponent,
    PlotFormComponent,
    FileUploadComponent,
    ServiceChipListComponent,
    DxPopupModule,
    DxButtonModule,
    FeasibilityHomeComponent,
    DeviceHomeComponent,
    DeviceListComponent,
    CreateEditDeviceModalComponent,
    DevicePortListComponent,
    DeviceSplitterListComponent,
    FeasibilitySingleComponent,
    AddressFormComponent,
    ClientFeasibilityHistoryListComponent,
    EquipmentHomeComponent,
    EquipmentListComponent,
    CreateEditEquipmentModalComponent,
    ThirdPartyModule,
    TraceModalComponent,
    UsersSingleModalComponent,
    ProximityDetailComponent,
    HeadendDetailComponent, 
    GoogleMapView,
    AddressAutocompleteComponent,

  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'auto' },
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
})
export class GeneralModule {}
